import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { type CSSProperties, useMemo } from "react";
import { type DropTargetMonitor, useDrop } from "react-dnd";

import { useMeshContext } from "@kraaft/helper-hooks";
import type { Size, UnknownObject } from "@kraaft/shared/core/types";
import { useCallbackRealtime } from "@kraaft/shared/core/utils/hooks";
import { getSpacingFromSizeValue, PixelSize, Spacing } from "@kraaft/ui";

import { AnimatedDraggableRow } from "./animatedDraggableRow";
import type { DraggableRowProps } from "./draggableRow";
import { ORDERABLE_LIST_ANIMATION_DURATION_MS } from "./orderableList.constants";
import { OrderableListContext } from "./orderableList.context";
import type {
  OrderableListDragItem,
  OrderableListRow,
  OrderableListRows,
  ReorderPlacement,
  RowDropInfo,
  SourceType,
  TargetType,
} from "./orderableList.types";
import {
  getDirectionFromIndexes,
  orderList,
  useDroppableAcceptTypes,
} from "./orderableList.utils";
import { useDraggingState } from "./useDraggingState";

const DEFAULT_PLACEHOLDER_SIZE: Size = {
  height: 50,
  width: 100,
};

export type OrderableListContentProps<T extends OrderableListRow> = {
  testId?: string;
  rows: OrderableListRows<T>;
  RowRenderer: DraggableRowProps<T>["Renderer"];
  withHandle?: boolean;
  rowContainerClassName?: string;
  targetType?: TargetType;
  onRequestReorder?: (
    sourceKey: string,
    placement: ReorderPlacement,
    targetKey: string,
  ) => void;
  onExternalTypeDrop?: (
    type: SourceType,
    item: UnknownObject,
    target: RowDropInfo | undefined,
  ) => void;
  grow?: boolean;
  paddingTop?: Spacing | number;
  paddingBottom?: Spacing | number;
  rowGap?: Spacing | number;
};

export const OrderableListContent = <T extends OrderableListRow>({
  testId,
  rows,
  RowRenderer,
  withHandle,
  rowContainerClassName,
  targetType,
  onRequestReorder,
  onExternalTypeDrop,
  grow,
  paddingTop = Spacing.NONE,
  paddingBottom = Spacing.NONE,
  rowGap = Spacing.NONE,
}: OrderableListContentProps<T>) => {
  const classes = useStyles();

  const { sourceType, onDragEnd, identifier, setCurrentlyHoveredRow } =
    useMeshContext(OrderableListContext);

  const currentlyHoveredRowKey = useMeshContext(
    OrderableListContext,
    (state) => state.currentlyHoveredRow?.key,
  );
  const currentlyHoveredRowIndex = useMeshContext(
    OrderableListContext,
    (state) => state.currentlyHoveredRow?.index,
  );
  const currentlyHoveredRowPlacement = useMeshContext(
    OrderableListContext,
    (state) => state.currentlyHoveredRow?.placement,
  );

  const isDragging = useDraggingState();

  const orderedRows = useMemo(() => orderList(rows), [rows]);

  const handleDragEnd = useCallbackRealtime(
    (
      [_currentlyHoveredRowKey, _currentlyHoveredRowPlacement],
      item: OrderableListDragItem<T>,
      monitor: DropTargetMonitor,
    ) => {
      onDragEnd?.();
      const type = monitor.getItemType();

      if (item.listIdentifier === identifier) {
        if (
          _currentlyHoveredRowKey !== undefined &&
          _currentlyHoveredRowPlacement !== undefined
        ) {
          onRequestReorder?.(
            item.sourceKey,
            _currentlyHoveredRowPlacement,
            _currentlyHoveredRowKey,
          );
        }
      } else if (type !== null) {
        onExternalTypeDrop?.(
          type,
          item,
          _currentlyHoveredRowKey !== undefined &&
            _currentlyHoveredRowPlacement !== undefined
            ? {
                key: _currentlyHoveredRowKey,
                placement: _currentlyHoveredRowPlacement,
              }
            : undefined,
        );
      }

      setCurrentlyHoveredRow(null);
    },
    [
      identifier,
      onExternalTypeDrop,
      onRequestReorder,
      rows,
      setCurrentlyHoveredRow,
    ],
    [currentlyHoveredRowKey, currentlyHoveredRowPlacement],
  );

  const expandedTargetType = useDroppableAcceptTypes(sourceType, targetType);
  const [
    { isDraggingOver, placeholderHeight, isDraggingChild, draggedIndex },
    drop,
  ] = useDrop({
    accept: expandedTargetType,
    drop: handleDragEnd,
    collect: (monitor: DropTargetMonitor<OrderableListDragItem<T> | null>) => {
      const _isDraggingOver = monitor.isOver() && monitor.canDrop();
      const item = monitor.getItem();

      return {
        placeholderHeight: _isDraggingOver
          ? (monitor.getItem()?.placeholderSize ?? DEFAULT_PLACEHOLDER_SIZE)
              .height
          : undefined,
        isDraggingOver: _isDraggingOver,
        isDraggingChild: item?.listIdentifier === identifier,
        draggedIndex: item?.sourceIndex,
      };
    },
  });

  const isExternalDrag = isDraggingOver && !isDraggingChild;

  const renderRenderedRows = useMemo(
    () =>
      orderedRows.map((item, _, { length: orderedRowsLength }) => {
        const direction = getDirectionFromIndexes(
          item.computedIndex,
          draggedIndex,
          currentlyHoveredRowIndex,
          currentlyHoveredRowPlacement,
          orderedRowsLength - 1,
          isExternalDrag,
        );

        return (
          <AnimatedDraggableRow
            key={item.key}
            item={item}
            Renderer={RowRenderer}
            containerClassName={rowContainerClassName}
            withHandle={withHandle}
            isDraggingOverList={isDraggingOver}
            direction={direction}
            draggedRowHeight={placeholderHeight}
            targetType={expandedTargetType}
          />
        );
      }),
    [
      orderedRows,
      draggedIndex,
      currentlyHoveredRowIndex,
      currentlyHoveredRowPlacement,
      isExternalDrag,
      RowRenderer,
      rowContainerClassName,
      withHandle,
      isDraggingOver,
      placeholderHeight,
      expandedTargetType,
    ],
  );

  const containerStyle = useMemo<CSSProperties | undefined>(() => {
    const style: CSSProperties = {
      paddingTop: getSpacingFromSizeValue(paddingTop),
      paddingBottom: getSpacingFromSizeValue(paddingBottom),
      rowGap: getSpacingFromSizeValue(rowGap),
    };

    if (isExternalDrag) {
      if (
        currentlyHoveredRowIndex === orderedRows.length - 1 &&
        currentlyHoveredRowPlacement === "after"
      ) {
        style.paddingTop = placeholderHeight;
      } else {
        style.paddingBottom = placeholderHeight;
      }
    }

    return style;
  }, [
    rowGap,
    currentlyHoveredRowIndex,
    currentlyHoveredRowPlacement,
    isExternalDrag,
    orderedRows.length,
    paddingBottom,
    paddingTop,
    placeholderHeight,
  ]);

  return (
    <div
      data-testid={testId}
      ref={drop}
      className={clsx(
        classes.container,
        grow && classes.withMaxPossibleHeightContainer,
        isDragging && classes.containerWithTransition,
      )}
      style={containerStyle}
    >
      {renderRenderedRows}
    </div>
  );
};

const useStyles = makeStyles({
  container: {
    minHeight: PixelSize.S8,
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
  },

  withMaxPossibleHeightContainer: {
    flexGrow: 1,
  },

  containerWithTransition: {
    transition: `padding ${ORDERABLE_LIST_ANIMATION_DURATION_MS}ms`,
  },
});

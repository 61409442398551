import { Tooltip } from "@mui/material";
import clsx from "clsx";
import { forwardRef, type MouseEventHandler, useCallback } from "react";

import {
  Color,
  Icon,
  IconSize,
  Preloader,
  webHighlightOnFocus,
} from "@kraaft/ui";

import { type ButtonProps, ButtonSize, ButtonType } from "./button.props";
import { generateButtonStyle } from "./button.styles.common";
import { useStyles } from "./button.styles.web";

export type ButtonHandle = HTMLButtonElement;

type WebButtonProps = Omit<ButtonProps, "onPress"> & {
  onPress?: MouseEventHandler<HTMLButtonElement>;
  checked?: boolean;
};

export const Button = forwardRef<ButtonHandle, WebButtonProps>(
  // eslint-disable-next-line complexity
  (
    {
      variant = "PRIMARY",
      destructive = false,
      size = "NORMAL",
      iconSize = "MEDIUM",
      alignContent = "center",
      type,
      style, // Could we get rid of that?
      disabled,
      testID,
      selected,
      condensed,
      tooltip = "",
      tooltipPlacement,
      contentColor, // Could we get rid of that?
      text,
      loading,
      icon,
      width,
      id,
      onPress,
      onPressIn,
      accessibilityLabel,
      autoFocus,
      checked,
      renderContent,
    },
    ref,
  ): JSX.Element => {
    const classes = useStyles();

    const hasText = (text?.length ?? 0) > 0;

    const variantColor = generateButtonStyle({
      variant,
      destructive,
      selected,
      disabled,
      contentColor,
    }).color;

    const textColor = loading ? Color.TRANSPARENT : variantColor; // keep undefined as much as possible not to override pseudo-selectors

    const sizeSuffix = ButtonSize[size];
    const variantSuffix = ButtonType[variant];
    const destructivePrefix = destructive ? "destructive" : "";
    const buttonClassName = clsx(
      classes.root,
      classes[`size${sizeSuffix}`],
      classes[`${destructivePrefix}${variantSuffix}`],
      (condensed || !hasText) && classes[`condensed${sizeSuffix}`],
      selected && classes.selected,
      alignContent === "left" && classes.alignLeft,
    );

    const defaultRenderContent = useCallback<
      NonNullable<ButtonProps["renderContent"]>
    >(
      (contentProps) => {
        return (
          <>
            {contentProps.loading && (
              <Preloader
                absoluteFill
                transparent
                color={contentProps.variantColor}
                size="small"
              />
            )}
            {contentProps.icon && (
              <Icon
                name={contentProps.icon}
                size={IconSize[contentProps.iconSize]}
                color={contentProps.textColor}
              />
            )}
            {text}
          </>
        );
      },
      [text],
    );

    const renderContentImplementation = renderContent ?? defaultRenderContent;

    return (
      <Tooltip
        title={tooltip}
        key={text}
        classes={{
          tooltip: classes.tooltip,
        }}
        placement={tooltipPlacement}
      >
        <button
          className={buttonClassName}
          role={checked !== undefined ? "checkbox" : undefined}
          aria-checked={checked}
          aria-label={accessibilityLabel ?? text ?? tooltip}
          ref={ref}
          disabled={disabled || loading}
          id={id}
          onClick={onPress}
          onMouseDown={onPressIn}
          type={type}
          style={{ width, color: textColor, alignContent, ...style }}
          data-testid={testID}
          // biome-ignore lint/a11y/noAutofocus: <explanation>
          autoFocus={autoFocus}
          {...webHighlightOnFocus}
        >
          {renderContentImplementation({
            loading,
            variantColor,
            iconSize,
            textColor,
            icon,
          })}
        </button>
      </Tooltip>
    );
  },
);

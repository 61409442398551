/* eslint-disable complexity */
// @TODO: reduce complexity below 10 if possible

import fromPairs from "lodash/fromPairs";
import groupBy from "lodash/groupBy";
import mapValues from "lodash/mapValues";
import orderBy from "lodash/orderBy";
import { Dictionary, StrictOmit } from "ts-essentials";

import { compactMap } from "@kraaft/helper-functions";
import {
  isOtherAcquisitionSource,
  PoolAcquisitionSource,
  poolAcquisitionSources,
} from "@kraaft/shared/components/onboarding/askPoolAcquisitionSource/poolAcquisitionSource";
import {
  isOtherCompanyIndustry,
  poolCompanyIndustries,
  PoolCompanyIndustry,
} from "@kraaft/shared/components/onboarding/askPoolCompanyIndustry/poolCompanyIndustry";
import {
  PoolCompanySize,
  poolCompanySizes,
} from "@kraaft/shared/components/onboarding/askPoolCompanySize/poolCompanySize";
import {
  isOtherJob,
  UserJob,
  userJobs,
} from "@kraaft/shared/components/onboarding/askUserJob/userJob";
import { Company } from "@kraaft/shared/core/modules/company/company.state";
import { Dummy } from "@kraaft/shared/core/modules/dummy/dummy";
import { fileAllocator } from "@kraaft/shared/core/modules/file/fileAllocator";
import { Form } from "@kraaft/shared/core/modules/form/formState";
import {
  LibrarySchema,
  LibrarySchemaLanguage,
  LibrarySchemaPresentation,
} from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { MapOverlay } from "@kraaft/shared/core/modules/mapOverlay/mapOverlay.state";
import { MiniMedia } from "@kraaft/shared/core/modules/miniMedia/miniMedia.state";
import { CompositeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import { Pool } from "@kraaft/shared/core/modules/pool/pool";
import { UserUnreadPools } from "@kraaft/shared/core/modules/pool/poolState";
import { PoolSchemaReportTemplate } from "@kraaft/shared/core/modules/reportTemplate/reportTemplate.state";
import * as RoomTypes from "@kraaft/shared/core/modules/room/roomState";
import { RoomSchemaVisibility } from "@kraaft/shared/core/modules/roomSchemaVisibility/roomSchemaVisibility.state";
import {
  KFolderSchema,
  KRoomSchema,
  KSchema,
  KSchemaColumn,
  KSchemaColumnBase,
  KSchemaSection,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { KSchemaConversion } from "@kraaft/shared/core/modules/schema/schema.conversion";
import { SchemaLibraryTag } from "@kraaft/shared/core/modules/schemaLibraryTag/schemaLibraryTagState";
import { SchemaTemplate } from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateState";
import { SchemaView } from "@kraaft/shared/core/modules/schemaView/schemaViewState";
import { UserPool } from "@kraaft/shared/core/modules/userPool/userPool.state";
import { Workflow } from "@kraaft/shared/core/modules/workflows/types";
import { WorkflowConversion } from "@kraaft/shared/core/modules/workflows/workflow.conversion";
import {
  FirestorePool,
  UserPoolRole,
} from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { prepareDownloadUrl } from "@kraaft/shared/core/services/firestore/prepareDownloadUrl";
import { FirestoreTypes } from "@kraaft/shared/core/services/firestore/sdk";
import { nullId } from "@kraaft/shared/core/utils/utils";
import {
  PoolAdmin,
  SerializedDirectory,
} from "@kraaft/web/src/core/modules/poolAdmin/poolAdminState";

import { OnboardingState, User } from "../../modules/user/userState";
import * as Types from "./firestoreTypes";
import { normalizeAttachment } from "./normalizeAttachment";
import { parseDate } from "./parseDate";

// Label

export function normalizeLabel(
  id: string,
  { name }: Types.FirestoreLabel,
): RoomTypes.Label {
  return { id, name };
}

// This function accept any type of parameters, check are done inside
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function decodeFirebaseTimestamps(obj: any): any {
  if (obj instanceof Date) {
    return obj;
  }
  if (obj?.toDate) {
    return obj.toDate();
  }
  if (Array.isArray(obj)) {
    return obj.map(decodeFirebaseTimestamps);
  }
  if (obj instanceof Object) {
    return Object.entries(obj).reduce(
      (map, [key, value]) => {
        map[key] = decodeFirebaseTimestamps(value);
        return map;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      },
      {} as Dictionary<any>,
    );
  }
  return obj;
}

// Pool

const DEFAULT_TIME_ZONES: { [key in Types.FirestorePoolLanguage]: string } = {
  "fr-FR": "Europe/Paris",
  "en-GB": "Europe/London",
  "en-US": "America/New_York",
  "it-IT": "Europe/Rome",
  "de-DE": "Europe/Berlin",
  "es-ES": "Europe/Madrid",
};

export function getDefaultTimeZoneForLanguage(
  language: Types.FirestorePoolLanguage,
) {
  return DEFAULT_TIME_ZONES[language] ?? "Europe/Paris";
}

export const isValidPoolCompanySize = (
  companySize: string,
): companySize is PoolCompanySize =>
  (poolCompanySizes as readonly string[]).includes(companySize);

export const isValidPoolCompanyIndustry = (
  companyIndustry: string,
): companyIndustry is PoolCompanyIndustry =>
  (poolCompanyIndustries as readonly string[]).includes(companyIndustry) ||
  isOtherCompanyIndustry(companyIndustry);

export const isValidPoolAcquisitionSource = (
  acquisitionSource: string,
): acquisitionSource is PoolAcquisitionSource =>
  (poolAcquisitionSources as readonly string[]).includes(acquisitionSource) ||
  isOtherAcquisitionSource(acquisitionSource);

export function normalizePool(id: string, data: FirestorePool): Pool {
  return {
    id,
    name: data.name,
    creatorId: data.creatorId,
    currencyCode: data.currencyCode,
    external: data.external,
    logoDownloadUrl: data.logoDownloadUrl,
    poolLanguage: data.language,
    timeZone: data.timeZone ?? getDefaultTimeZoneForLanguage(data.language),
    isFreemium: data.isFreemium,
    identityProviderId: data.identityProviderId || undefined,
    identityProviderSubConfigId: data.identityProviderSubConfigId || undefined,
    companyInfos: {
      size:
        data.companyInfos?.size &&
        isValidPoolCompanySize(data.companyInfos.size)
          ? data.companyInfos.size
          : undefined,
      industry:
        data.companyInfos?.industry &&
        isValidPoolCompanyIndustry(data.companyInfos.industry)
          ? data.companyInfos.industry
          : undefined,
    },
    acquisitionInfos: {
      source:
        data.acquisitionInfos?.source &&
        isValidPoolAcquisitionSource(data.acquisitionInfos.source)
          ? data.acquisitionInfos.source
          : undefined,
    },
    companyId: data.companyId,
    aiDirectoryEnabled: data.aiDirectoryEnabled,
  };
}

function normalizeDefaultDirectoryTree(
  serializedDirectories: Types.FirestoreSerializedDirectory[] | undefined,
  indexPrefix = "",
): SerializedDirectory[] {
  return (
    serializedDirectories?.map((directory, index) => {
      const id = `${indexPrefix ? `${indexPrefix}-` : ""}${index.toString()}`;

      return {
        id,
        name: directory.name,
        directories: directory.directories
          ? normalizeDefaultDirectoryTree(directory.directories, id)
          : undefined,
      };
    }) ?? []
  );
}

export function normalizePoolAdmin(
  id: string,
  data: Types.FirestorePoolAdmin,
): PoolAdmin {
  return {
    poolId: id,
    microsoftStoragePoolSync: data.microsoftStoragePoolSync && {
      url: data.microsoftStoragePoolSync.url,
      synchronizedAt: data.microsoftStoragePoolSync.synchronizedAt
        ? parseDate(data.microsoftStoragePoolSync.synchronizedAt)
        : undefined,
      error: normalizeErrorInfo(data.microsoftStoragePoolSync.error),
    },
    inboundEmails: data.inboundEmails,
    defaultDirectoryTree: normalizeDefaultDirectoryTree(
      data.defaultDirectoryTree,
    ),
  };
}

export function normalizeMiniMedias(
  miniMedias: FirestoreTypes.QueryDocumentSnapshot[],
): MiniMedia[] {
  return compactMap(miniMedias, (mdoc) => {
    const m = mdoc.data() as Types.FirestoreMiniMedia;
    if (m.type === "image") {
      return {
        id: mdoc.id,
        isMiniMedia: true,
        createdAt: parseDate(m.createdAt),
        updatedAt: parseDate(m.updatedAt),
        geolocation: m.geolocation,
        messageId: m.messageId,
        roomId: m.roomId,
        preview: {
          downloadUrl: prepareDownloadUrl(m.preview.downloadUrl),
          size: m.preview.size,
        },
        type: m.type,
        name: m.name,
      };
    }
    if (m.type === "document") {
      return {
        id: mdoc.id,
        isMiniMedia: true,
        createdAt: parseDate(m.createdAt),
        updatedAt: parseDate(m.updatedAt),
        messageId: m.messageId,
        roomId: m.roomId,
        type: m.type,
        downloadUrl: prepareDownloadUrl(m.downloadUrl),
        name: m.name,
      };
    }
    return undefined;
  });
}

interface FirestoreErrorInfo {
  lastMessage: string;
  firstFailedAt: FirestoreTypes.Timestamp;
  lastFailedAt: FirestoreTypes.Timestamp;
}

function normalizeErrorInfo(errorInfo: FirestoreErrorInfo | undefined) {
  return (
    errorInfo && {
      lastMessage: errorInfo.lastMessage,
      lastFailedAt: parseDate(errorInfo.lastFailedAt),
      firstFailedAt: parseDate(errorInfo.firstFailedAt),
    }
  );
}

export function normalizeFirestoreRoom(
  id: string,
  data: Types.FirestoreRoom,
): StrictOmit<RoomTypes.Room, "record"> {
  const type = normalizeRoomType(data.type);

  const room: StrictOmit<RoomTypes.Room, "record"> = {
    id,
    createdAt: parseDate(data.createdAt),
    creatorUserId: data.creatorUserId,
    visibility: data.visibility,
    poolId: data.poolId,
    lastEventAt: parseDate(
      data.lastEventAt ?? data.lastMessage?.createdAt ?? data.createdAt,
    ),
    lastMessage: data.lastMessage && {
      id: data.lastMessage.id,
      senderId: data.lastMessage.senderId,
      createdAt: parseDate(data.lastMessage.createdAt),
      type: data.lastMessage.type,
      text: data.lastMessage.text,
    },
    isArchivedForAll: Boolean(data.isArchivedForAll),
    members: data.members,
    updatedAt: parseDate(data.updatedAt),
    external: data.external?.microsoftStorage && {
      microsoftStorage: {
        url: data.external.microsoftStorage.url,
        synchronizedAt:
          data.external.microsoftStorage.synchronizedAt &&
          parseDate(data.external.microsoftStorage.synchronizedAt),
        error: normalizeErrorInfo(data.external.microsoftStorage.error),
      },
    },
    type,
    isClonedFromDemo: data.isClonedFromDemo,
    emoji: data.emoji,
  };
  return room;
}

function normalizeRoomType(
  firestoreType: Types.FirestoreRoomType,
): RoomTypes.RoomType {
  if (firestoreType === Types.FirestoreRoomType.EVERYONE) {
    return RoomTypes.RoomType.EVERYONE;
  }
  return RoomTypes.RoomType.DEFAULT;
}

export function normalizeFirestoreUserRoom(
  data: Types.FirestoreUserRoom,
): RoomTypes.UserRoom {
  return {
    id: data.roomId,
    isArchived: data.isArchived,
    readAt: data.readAt ? parseDate(data.readAt) : undefined,
    lastEventAt: parseDate(data.lastEventAt),
    lastReadMessageCreatedAt: parseDate(
      data.lastReadMessageCreatedAt || undefined,
    ),
    notificationFilter: data.allowedNotificationSource,
    lastReadMessageId: data.lastReadMessageId,
    isMarkedUnread: data.isMarkedUnread,
  };
}

// User

export function normalizeUserPool(
  userPool: FirestoreTypes.DocumentSnapshot,
): UserPool {
  return {
    notificationFilter: userPool.data()?.allowedNotificationSource,
  };
}

export function normalizeUserPoolInfo(pool: Types.FirestoreUserPoolInfo) {
  return {
    role: pool.role || UserPoolRole.STANDARD,
    joinedAt: parseDate(pool.joinedAt),
  };
}

export function isValidUserJob(job: string): job is UserJob {
  return (userJobs as readonly string[]).includes(job) || isOtherJob(job);
}

export function normalizeUser(data: Types.FirestoreUser): User {
  return {
    username: data.username,
    firstName: data.firstName,
    lastName: data.lastName,
    usernameColor: data.usernameColor,
    pools: mapValues(data.inPools, normalizeUserPoolInfo),
    job: data.job && isValidUserJob(data.job) ? data.job : undefined,
    isClonedFromDemo: data.isClonedFromDemo,
  };
}

export function normalizeUsers(
  docs: FirestoreTypes.QueryDocumentSnapshot[],
): Dictionary<User> {
  if (docs === null || docs.length === 0) {
    return {};
  }
  return docs.reduce(
    (users, doc) => {
      const data = doc.data() as Types.FirestoreUser;
      users[doc.id] = normalizeUser(data);
      return users;
    },
    {} as Dictionary<User>,
  );
}

export function normalizeRoomMember(
  data: Types.FirestoreUserRoom,
): RoomTypes.RoomMember {
  return {
    userId: data.userId,
    lastReadMessageCreatedAt: data.lastReadMessageCreatedAt
      ? parseDate(data.lastReadMessageCreatedAt)
      : undefined,
    lastReadMessageId: data.lastReadMessageId,
    readAt: data.readAt ? parseDate(data.readAt) : undefined,
    roomId: data.roomId,
  };
}

// Forms
export function normalizeFirestoreForms(
  documents: FirestoreTypes.QueryDocumentSnapshot<Types.FirestoreFillableForm>[],
): Form[] {
  return compactMap(documents, normalizeFirestoreKizeoForm);
}

function normalizeFirestoreKizeoForm(
  document: FirestoreTypes.QueryDocumentSnapshot<Types.FirestoreFillableForm>,
): Form | undefined {
  const data = document.data();
  if ("type" in data && data.type === "kizeo") {
    return {
      id: document.id,
      type: "kizeo",
      name: data.name,
      kizeoFormId: data.kizeo.formId,
      exports: data.kizeo.exports,
    };
  }
  return undefined;
}

// Templates
export function normalizeTemplates(
  docs: FirestoreTypes.QueryDocumentSnapshot[],
): PoolSchemaReportTemplate[] {
  const hasIncoherentIndexes: Set<string> = new Set();

  const groupedReportTemplates = groupBy(
    docs.map<PoolSchemaReportTemplate>((doc) => {
      const data = doc.data() as Types.FirestorePoolSchemaReportTemplate;

      return {
        id: doc.id,
        poolId: data.poolId,
        schemaId: data.schemaId,
        index: data.index,
        enabled: data.enabled,
        updatedAt: parseDate(data.updatedAt),
        ...(data.variant === "default"
          ? {
              variant: "default",
              format: data.format === "xlsx" ? "xlsx" : "docx",
            }
          : {
              variant: "custom",
              name: data.name,
              file: {
                filename: data.file.filename,
                downloadUrl: prepareDownloadUrl(data.file.downloadUrl),
              },
              format: data.file.format === "xlsx" ? "xlsx" : "docx",
            }),
      };
    }),
    (reportTemplate) => reportTemplate.schemaId,
  );

  for (const [schemaId, receivedIndexesForSchema] of Object.entries(
    groupedReportTemplates,
  )) {
    if (
      orderBy(
        receivedIndexesForSchema,
        (reportTemplate) => reportTemplate.index,
      ).some((reportTemplate, index) => reportTemplate.index !== index)
    ) {
      hasIncoherentIndexes.add(schemaId);
    }
  }

  for (const schemaId of hasIncoherentIndexes) {
    console.warn(
      `normalizeTemplates :: found incorrect indexes for templates of schema ${schemaId}`,
    );

    groupedReportTemplates[schemaId] = orderBy(
      groupedReportTemplates[schemaId],
      (reportTemplate) => reportTemplate.index,
    ).map((reportTemplate, index) => ({
      ...reportTemplate,
      index,
    }));
  }

  return Object.values(groupedReportTemplates).reduce(
    (allReportTemplates, reportTemplates) => {
      for (const reportTemplate of reportTemplates) {
        allReportTemplates.push(reportTemplate);
      }
      return allReportTemplates;
    },
    [],
  );
}

function normalizeSchemaElementDescription(
  description: Types.FirestoreSchemaElementDescription,
) {
  return {
    text: description.text,
    image: description.image
      ? {
          downloadUrl: description.image.downloadUrl,
          filename: description.image.filename,
        }
      : undefined,
    document: description.document
      ? {
          downloadUrl: description.document.downloadUrl,
          filename: description.document.filename,
        }
      : undefined,
  };
}

function normalizeSchemaColumn(
  column: Types.FirestoreSchemaColumn,
): KSchemaColumn | undefined {
  const base: KSchemaColumnBase = {
    elementType: "column",
    index: column.index,
    key: column.key,
    name: column.name,
    reportKey: column.reportKey,
    isOptimistic: false,
    nonDeletable: column.nonDeletable,
    nonEditableName: column.nonEditableName,
    condition: column.condition
      ? normalizeCompositeCondition(column.condition)
      : undefined,
    description: column.description
      ? normalizeSchemaElementDescription(column.description)
      : undefined,
  };
  const definition =
    KSchemaConversion.toColumnDefinitionFromFirestoreColumnDefinition(
      column.definition,
    );

  if (definition !== undefined) {
    return { ...base, ...definition } as KSchemaColumn;
  }

  console.warn("Could not deserialize schema column", column);
  return undefined;
}

function normalizeSchemaSection(
  data: Types.FirestoreSchemaSection,
): KSchemaSection {
  return {
    key: data.key,
    reportKey: data.reportKey,
    index: data.index,
    name: data.name,
    elementType: "section",
    color: data.color,
    condition: data.condition
      ? normalizeCompositeCondition(data.condition)
      : undefined,
    elements: fromPairs(
      compactMap(Object.entries(data.elements), ([key, element]) => {
        if (element.elementType === "column") {
          const deserializedColumn = normalizeSchemaColumn(element);
          if (!deserializedColumn) {
            return undefined;
          }
          return [key, deserializedColumn];
        }
        if (element.elementType === "section") {
          return [key, normalizeSchemaSection(element)];
        }
        return undefined;
      }),
    ),
    description: data.description
      ? normalizeSchemaElementDescription(data.description)
      : undefined,
  };
}

function normalizeFolderSchema(
  id: string,
  data: Types.FirestoreFolderSchema,
): KFolderSchema {
  return {
    updatedAt: parseDate(data.updatedAt),
    id,
    collection: data.collection,
    index: data.index,
    poolId: data.poolId,
    name: data.name,
    description: data.description,
    autoIncrement: data.autoIncrement,
    color: data.color,
    icon: data.icon,
    type: data.type === "checkbox" ? "checkbox" : "custom", // stoppingPoint is considered as custom
    rootSection: normalizeSchemaSection(data.rootSection),
    highlightedCheckbox: data.highlightedCheckbox,
    defaultVisibility: data.defaultVisibility,
  };
}

function normalizeRoomSchema(
  id: string,
  data: Types.FirestoreRoomSchema,
): KRoomSchema {
  return {
    updatedAt: parseDate(data.updatedAt),
    id,
    collection: data.collection,
    poolId: data.poolId,
    name: data.name,
    rootSection: normalizeSchemaSection(data.rootSection),
    roomCardDisplayColumns: data?.roomCardDisplayColumns,
    icon: "message-circle-01",
  };
}

function normalizeSchema(
  id: string,
  data: Types.FirestoreSchema,
): KSchema | undefined {
  switch (data.collection) {
    case "folder":
      return normalizeFolderSchema(id, data);
    case "room":
      return normalizeRoomSchema(id, data);
  }
}

export function normalizeSchemas(
  docs: FirestoreTypes.QueryDocumentSnapshot[],
): KSchema[] {
  return compactMap(docs, (doc) => {
    const data = doc.data() as Types.FirestoreSchema;

    const normalized = normalizeSchema(doc.id, data);
    return normalized;
  });
}

export function normalizeSchemaTemplates(
  docs: FirestoreTypes.QueryDocumentSnapshot[],
): SchemaTemplate[] {
  return docs.map((doc) => {
    const data = doc.data() as Types.FirestoreSchemaTemplate;

    return {
      id: doc.id,
      schemaId: data.schemaId,
      poolId: data.poolId,
      name: data.name,
      modifiers: data.modifiers,
    };
  });
}

function normalizeCompositeCondition(
  condition: Types.FirestoreCompositeCondition,
): CompositeCondition {
  return {
    type: "composite",
    operator: condition.operator,
    conditions: compactMap(condition.conditions, (subCondition) => {
      if (subCondition.type === "composite") {
        return normalizeCompositeCondition(subCondition);
      }
      if (subCondition.type === "standalone-record") {
        return {
          type: "standalone-record",
          columnKey: subCondition.columnKey,
          predicate: subCondition.predicate,
        };
      }
      if (subCondition.type === "couple-record") {
        const value = WorkflowConversion.toValue(
          subCondition.predicate,
          subCondition.value,
        );
        if (!value) {
          return undefined;
        }
        return {
          type: "couple-record",
          columnKey: subCondition.columnKey,
          predicate: subCondition.predicate,
          value,
        };
      }
      if (subCondition.type === "metadata") {
        return {
          type: "metadata",
          predicate: subCondition.predicate,
          value: subCondition.value
            ? KSchemaConversion.toProperty(subCondition.value)
            : undefined,
        };
      }
      if (subCondition.type === "standalone-metadata") {
        return {
          type: "standalone-metadata",
          predicate: subCondition.predicate,
        };
      }
    }),
  };
}

export function normalizeWorkflows(
  docs: FirestoreTypes.QueryDocumentSnapshot[],
): Workflow[] {
  return docs.map((doc) => {
    const data = doc.data() as Types.FirestoreWorkflow;

    return {
      id: doc.id,
      schemaId: data.schemaId,
      poolId: data.poolId,
      name: data.name,
      condition: normalizeCompositeCondition(data.condition),
      actions: data.actions,
      enabled: data.enabled,
      createdAt: parseDate(data.createdAt),
      updatedAt: parseDate(data.updatedAt),
    };
  });
}

export function normalizeModularRecordPropertiesValue(
  property: Types.FirestoreModularRecord["properties"][string],
) {
  switch (property.columnType) {
    case "date":
      return property.value ? parseDate(property.value) : undefined;
    case "attachment":
      return property.value
        ? compactMap(property.value, normalizeAttachment)
        : undefined;
    case "signature":
      return property.value ? normalizeAttachment(property.value) : undefined;
    default:
      return property.value;
  }
}

export function normalizeUserUnreadPools(
  docs: FirestoreTypes.QueryDocumentSnapshot[],
): UserUnreadPools[] {
  return docs.map((doc) => {
    const data = doc.data() as Types.FirestoreUserUnreadPools;

    return {
      unreadPools: data.unreadPools,
      updatedAt: parseDate(data.updatedAt),
      userId: data.userId,
    };
  });
}

export function normalizeSchemaViews(
  docs: FirestoreTypes.QueryDocumentSnapshot[],
): SchemaView[] {
  return docs.map<SchemaView>((doc) => {
    const data = doc.data() as Types.FirestoreSchemaView;

    return {
      id: doc.id,
      name: data.name,
      schemaId: data.schemaId,
      filters: normalizeCompositeCondition(data.filters),
      formats: data.formats,
    };
  });
}

// Onboarding

export function normalizeUserOnboardingState(
  data: Types.FirestoreUserOnboarding,
): OnboardingState {
  return {
    shouldShowConversationOnboardingButton:
      data.shouldShowConversationOnboarding ?? false, // flag has evolved, now shows a button
    shouldShowPoolOnboarding: data.shouldShowPoolOnboarding ?? false,
  };
}

export function normalizeMapOverlays(
  docs: FirestoreTypes.QueryDocumentSnapshot[],
): MapOverlay[] {
  return compactMap(docs, (doc) => {
    const data = doc.data() as Types.FirestoreMapOverlay;

    return {
      id: doc.id,
      poolId: data.poolId,
      name: data.name,
      updatedAt: parseDate(data.updatedAt),
      file: {
        filename: data.file.filename,
        downloadUrl: prepareDownloadUrl(data.file.downloadUrl),
      },
    } satisfies MapOverlay;
  });
}

function normalizeLibrarySchemaPresentation(
  data: Types.FirestoreLibrarySchemaPresentation,
): LibrarySchemaPresentation {
  return {
    description: data.description,
    coverPictureUrl: data.coverPicture
      ? prepareDownloadUrl(data.coverPicture.downloadUrl)
      : null,
    video:
      data.video && data.video.type === "youtube"
        ? {
            type: data.video.type,
            id: data.video.id,
            url: data.video.url,
          }
        : undefined,
    sampleReport: data.sampleReport
      ? {
          filename: data.sampleReport.filename,
          downloadUrl: prepareDownloadUrl(data.sampleReport.downloadUrl),
        }
      : null,
  };
}

export function normalizeLibrarySchema(
  docs: FirestoreTypes.QueryDocumentSnapshot<Types.FirestoreLibrarySchema>[],
): LibrarySchema[] {
  return compactMap(docs, (doc) => {
    const data = doc.data();

    return {
      id: doc.id,
      name: data.name,
      icon: data.icon,
      companyId: data.companyId === nullId ? undefined : data.companyId,
      tagIds: data.tags?.map((tag) => tag.id),
      schema: {
        id: doc.id,
        autoIncrement: data.index,
        collection: "folder",
        color: "#000000",
        description: "",
        index: data.index,
        type: "custom",
        poolId: "",
        updatedAt: parseDate(data.updatedAt),
        name: data.name,
        icon: data.icon,
        rootSection: normalizeSchemaSection(data.definition),
        highlightedCheckbox: data.highlightedCheckbox,
        defaultVisibility: "hidden",
      },
      enabled: data.enabled,
      index: data.index,
      presentation: normalizeLibrarySchemaPresentation(data.presentation),
      language: data.language,
      reportTemplates:
        data.reportTemplates.map((template) => ({
          ...template,
          updatedAt: parseDate(template.updatedAt),
          ...(template.variant === "default"
            ? {
                variant: "default",
                format: template.format === "xlsx" ? "xlsx" : "docx",
              }
            : {
                variant: "custom",
                name: template.name,
                file: {
                  filename: template.file.file.filename,
                  downloadUrl: prepareDownloadUrl(
                    fileAllocator.parseRemotePath(
                      template.file.file.downloadUrl,
                    ),
                  ),
                },
                format: template.file.format === "xlsx" ? "xlsx" : "docx",
              }),
        })) || [],
      updatedAt: parseDate(data.updatedAt),
    } satisfies LibrarySchema;
  });
}

export function normalizeRoomSchemaVisibility(
  id: string,
  data: Types.FirestoreRoomSchemaVisibility,
): RoomSchemaVisibility {
  return {
    roomId: id,
    whitelist: new Set(data.whitelist),
    updatedAt: parseDate(data.updatedAt),
  };
}

export function normalizeCompanies(data: Types.FirestoreCompany[]): Company[] {
  return data.map((d) => ({
    id: d.id,
    name: d.name,
    color: d.color,
  }));
}

export function normalizeDummies(data: Types.FirestoreDummy[]): Dummy[] {
  return data.map((d) => ({
    id: d.id,
    name: d.name,
    updatedAt: parseDate(d.updatedAt),
    files: compactMap(d.files, normalizeAttachment),
  }));
}

export function normalizeSchemaLibraryTags(
  data: Array<Types.FirestoreSchemaLibraryTags>,
): Array<SchemaLibraryTag> {
  return data.flatMap((tags) =>
    tags.order.map((tag, index) => ({
      id: tag.id,
      index: index,
      name: tag.name,
      updatedAt: parseDate(tag.updatedAt),
      language: tag.language as LibrarySchemaLanguage,
    })),
  );
}

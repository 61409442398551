import { Dictionary } from "ts-essentials";

import { RemotePath } from "@kraaft/shared/core/modules/file/file";
import * as MessageTypes from "@kraaft/shared/core/modules/message/messageState";
import * as RoomTypes from "@kraaft/shared/core/modules/room/roomState";
import { KSchemaIcon } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { Modifier } from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateState";
import { Action, Workflow } from "@kraaft/shared/core/modules/workflows/types";
import { FirestoreTypes } from "@kraaft/shared/core/services/firestore/sdk";
import { GeoCoordinates, GeoLocation } from "@kraaft/shared/core/types";

// Label

export interface FirestoreLabel {
  poolId: string;
  name: string;
}

// Timestamp

export type FirestoreTimestamp =
  | Date
  | string
  | FirestoreTypes.Timestamp
  | LegacyFirestoreTimestamp;

export type FirestoreDate = FirestoreTypes.Timestamp | LegacyFirestoreTimestamp;

export interface LegacyFirestoreTimestamp {
  _seconds: number;
  _nanoseconds: number;
}

// User
export interface FirestoreUser {
  username: string;
  firstName?: string;
  lastName?: string;
  usernameColor?: string;
  inPools: Dictionary<FirestoreUserPoolInfo>;
  superRole?: string;
  debug?: boolean;
  pranked?: boolean;
  job?: string;
  isClonedFromDemo?: boolean;
  createdAt: FirestoreTimestamp;
}

export enum UserPoolRole {
  OUTSIDER = "outsider",
  EXTERNAL = "external",
  STANDARD = "standard",
  ADMIN = "administrator",
  OWNER = "owner",
}

export interface FirestoreUserPoolInfo {
  role: UserPoolRole;
  joinedAt: FirestoreTimestamp;
}

export interface FirestoreUserPool {
  // @todo change endpoint and parameter name
  allowedNotificationSource: "all" | "none";
}

// Pool

export type FirestorePoolLanguage =
  | "fr-FR"
  | "en-GB"
  | "en-US"
  | "it-IT"
  | "de-DE"
  | "es-ES";

export interface FirestorePool {
  updatedAt: FirestoreTimestamp;
  name: string;
  creatorId: string | undefined;
  currencyCode?: string;
  external?: {
    kizeo?: {
      isSynchronized: boolean;
    };
  };
  logoDownloadUrl?: string;
  language: FirestorePoolLanguage;
  timeZone: string;
  isFreemium: boolean;
  identityProviderId?: string | null;
  identityProviderSubConfigId?: string | undefined;
  enabledFeatures?: {
    mapOverlays?: boolean;
  };
  companyInfos?: {
    size?: string;
    industry?: string;
  };
  acquisitionInfos?: {
    source?: string;
  };
  companyId: string | undefined;
  aiDirectoryEnabled: boolean | undefined;
}

export type FirestoreSerializedDirectory = {
  name: string;
  directories?: FirestoreSerializedDirectory[];
};

export interface FirestorePoolAdmin {
  microsoftStoragePoolSync?: {
    url: string;
    synchronizedAt?: FirestoreTimestamp;
    error?: FirestoreErrorInfo;
  };
  inboundEmails?: {
    [id: string]: {
      isActive: boolean;
      activatedAt: Date;
      email: string;
    };
  };
  defaultDirectoryTree?: FirestoreSerializedDirectory[];
}

// Messages

export type FirestoreMessage =
  | FirestoreTextMessage
  | FirestoreImageMessage
  | FirestoreAudioMessage
  | FirestoreVideoMessage
  | FirestoreDocumentMessage
  | FirestoreGeolocationMessage
  | FirestoreLogMessage;

export interface MessageReaction {
  reactedAt: FirestoreDate;
  emoji: string;
}

export interface FirestoreUserMessage {
  id: string;
  type: MessageTypes.MessageType;

  optimisticId: string | undefined;

  senderId: string;
  createdAt: FirestoreDate;
  updatedAt: FirestoreDate;

  answerTo: string | undefined;
  forwarded:
    | {
        by: string;
        from: string;
      }
    | undefined;
  deletedBy: string | undefined;
  deletedAt: FirestoreDate | undefined;

  modularObjects: FirestoreMessageModularModuleAttributes | undefined;
  reactions?: {
    [userId: string]: MessageReaction;
  };
}

export interface FirestoreTextMessage extends FirestoreUserMessage {
  type: "text";
  text: (
    | { type: "text"; content: string }
    | { type: "mention"; userId: string }
  )[];
  textModifiedAt: Date | undefined;
}

export interface FirestoreImageMessage extends FirestoreUserMessage {
  type: "image";
  attachment: FirestoreImageAttachment;
}

export interface FirestoreAudioMessage extends FirestoreUserMessage {
  type: "audio";
  attachment: FirestoreAudioAttachment;
}

export interface FirestoreVideoMessage extends FirestoreUserMessage {
  type: "video";
  attachment: FirestoreVideoAttachment;
}

export interface FirestoreDocumentMessage extends FirestoreUserMessage {
  type: "document";
  attachment: FirestoreDocumentAttachment;
}

export interface FirestoreGeolocationMessage extends FirestoreUserMessage {
  type: "geolocation";
  geolocation: GeoLocation;
  thumbnail: FirestoreStorageImagePreview;
}

export interface FirestoreLogMessage {
  type: "log";

  senderId: string;
  createdAt: Date;
  updatedAt: Date;

  event:
    | {
        type: "folderCompleted";
        folderId: string;
        folderName: string;
        schemaId: string;
        schemaName: string;
        relatedRessourceDeleted: boolean | undefined;
      }
    | {
        type: "folderUnchecked";
        folderId: string;
        folderName: string;
        schemaId: string;
        schemaName: string;
        relatedRessourceDeleted: boolean | undefined;
      }
    | {
        type: "folderCreated";
        folderId: string;
        folderName: string;
        schemaId: string;
        schemaName: string;
        relatedRessourceDeleted: boolean | undefined;
      }
    | {
        type: "internalMemberJoinedPool";
        userId: string;
        poolId: string;
      }
    | {
        type: "workflowMessage";
        initiatorId: string;
        text: string;
        source:
          | {
              type: "modularFolder" | "room";
              id: string;
              schemaId: string;
            }
          | undefined;
        relatedRessourceDeleted: boolean | undefined;
      };
}

export interface FirestoreMessageModularModuleAttribute {
  isFlagged: boolean;
  modularFolders: {
    [recordId: string]: Record<string, unknown>;
  };
}

export interface FirestoreMessageModularModuleAttributes {
  [schemaId: string]: FirestoreMessageModularModuleAttribute;
}

export type ImageFormat = "original" | "medium" | "small";

export interface ImageSize {
  width: number;
  height: number;
}

export interface FirestoreStorageImagePreview {
  file: FirestoreStorageFile;
  format: ImageFormat;
  size: ImageSize | undefined;
}

export interface FirestoreStorageFile {
  // storagePath: string; <- Exists but should not be used on front-end
  downloadUrl: RemotePath;
  filename: string;
}

// Attachments

export type FirestoreAttachment =
  | FirestoreImageAttachment
  | FirestoreVideoAttachment
  | FirestoreDocumentAttachment
  | FirestoreAudioAttachment;

interface FirestoreAttachmentBase {
  id: string;
  original: FirestoreStorageFile;
  createdAt: FirestoreTimestamp;
  messagePath: string | undefined;
  senderUserId: string;
}

export interface FirestoreDocumentAttachment extends FirestoreAttachmentBase {
  type: "document";
}

type FirestoreInputPartition =
  | {
      type: "text";
      content: string;
    }
  | {
      type: "mention";
      userId: string;
    }
  | {
      type: "mention-everyone";
    };

// TODO: wip, should I keep that ?
export interface FirestoreImageAttachment
  extends Omit<FirestoreAttachmentBase, "original"> {
  type: "image";
  caption: FirestoreInputPartition[] | undefined;
  captionModifiedAt: Date | undefined;
  captionModifiedBy: string | undefined;
  coords: GeoCoordinates | undefined;
  geolocation: GeoLocation;
  original: FirestoreStorageFile & {
    size: ImageSize | undefined;
  };
}

export interface FirestoreVideoAttachment extends FirestoreAttachmentBase {
  type: "video";
  caption: FirestoreInputPartition[] | undefined;
  captionModifiedAt: Date | undefined;
  captionModifiedBy: string | undefined;
  coords: GeoCoordinates | undefined;
  geolocation: GeoLocation;
  converted: {
    mp4: FirestoreStorageFile | undefined;
  };
}

export interface FirestoreAudioAttachment extends FirestoreAttachmentBase {
  type: "audio";
  transcription: FirestoreInputPartition[] | undefined;
  transcriptionLocale: string | undefined;
  transcriptionModifiedAt: Date | undefined;
}

// Room

export enum FirestoreRoomType {
  EXTERNAL = "external",
  DEFAULT = "default",
  EVERYONE = "everyone",
}

export interface FirestoreRoomSummary {
  createdAt: FirestoreTimestamp;
  creatorUserId: string;
  name: string;
  description?: string;
  visibility: RoomTypes.RoomVisibility;
  status?: string;
  responsibleUserId?: string | null;
  labels: string[]; // Label IDs
  poolId: string;
  lastEventAt?: Date;
  lastMessage?: {
    id: string;
    senderId: string;
    createdAt: FirestoreTimestamp;
    type: MessageTypes.MessageType;
    text: string | undefined;
  };
  geolocation: GeoLocation | undefined;

  type: FirestoreRoomType;
}

interface FirestoreErrorInfo {
  lastMessage: string;
  firstFailedAt: FirestoreTypes.Timestamp;
  lastFailedAt: FirestoreTypes.Timestamp;
}

interface FirestoreRoomBase extends FirestoreRoomSummary {
  incrementalId: number;
  updatedAt: FirestoreTimestamp;
  isArchivedForAll?: boolean;
  external?: {
    microsoftStorage: {
      url: string;
      synchronizedAt?: FirestoreTypes.Timestamp;
      error?: FirestoreErrorInfo;
    };
  };
  members: RoomTypes.RoomMembers;
  record: FirestoreModularRecord;
  isClonedFromDemo?: boolean;
  emoji: string;
}

export type FirestoreRoom = FirestoreRoomBase;

// User «» Room

export interface FirestoreUserRoom {
  // Keys
  userId: string;
  roomId: string;

  // Proper data
  updatedAt: FirestoreTimestamp;
  lastEventAt: FirestoreTimestamp;
  isArchived: boolean;
  isMarkedUnread: boolean | undefined;
  lastReadMessageId: string | undefined;
  lastReadMessageCreatedAt: FirestoreTimestamp | null;
  readAt: FirestoreTimestamp | null;
  // @todo change endpoint and parameter name
  allowedNotificationSource?: "inherit" | "all" | "only_mentions";
}

// Forms
export type FirestoreFillableForm =
  | FirestoreKizeoFillableForm
  | FirestoreFillableFormBase;

interface FirestoreFillableFormBase {
  updatedAt: Date;
  poolId: string;
  name: string;
}

type FirestoreKizeoFormExport =
  | {
      type: "defaultPdf";
    }
  | {
      type: "custom";
      docType: "word" | "excel";
      exportId: string;
      name: string;
      pdf: boolean;
    };

export interface FirestoreKizeoFillableForm extends FirestoreFillableFormBase {
  type: "kizeo";

  kizeo: {
    formId: string;
    exports?: FirestoreKizeoFormExport[];
  };
}

export interface FirestorePoolAdmin {
  external?: {
    kizeo?: {
      token: string;
    } | null;
  };
}

export interface FirestoreTemplateFile {
  filename: string;
  downloadUrl: RemotePath;
  format: string | undefined;
}

export interface FirestoreReportTemplate {
  index: number;
  enabled: boolean;
  updatedAt: FirestoreTimestamp;
}

export interface FirestoreCustomReportTemplate extends FirestoreReportTemplate {
  variant: "custom";
  name: string;
  file: FirestoreTemplateFile;
}

export interface FirestoreDefaultReportTemplate
  extends FirestoreReportTemplate {
  variant: "default";
  format: string | undefined;
}

export type FirestorePoolSchemaReportTemplate = (
  | FirestoreCustomReportTemplate
  | FirestoreDefaultReportTemplate
) & {
  poolId: string;
  schemaId: string;
};

export interface FirestoreLibraryTemplateFile {
  file: {
    filename: string;
    downloadUrl: string;
  };
  format: string | undefined;
}

export interface FirestoreLibraryReportTemplate {
  index: number;
  enabled: boolean;
  updatedAt: FirestoreTimestamp;
}

export interface FirestoreLibraryCustomReportTemplate
  extends FirestoreLibraryReportTemplate {
  variant: "custom";
  name: string;
  file: FirestoreLibraryTemplateFile;
}

export interface FirestoreLibraryDefaultReportTemplate
  extends FirestoreReportTemplate {
  variant: "default";
  format: string | undefined;
}

export type FirestoreLibrarySchemaReportTemplate = (
  | FirestoreLibraryCustomReportTemplate
  | FirestoreLibraryDefaultReportTemplate
) & {
  id: string;
};

// Modular Folders

interface AttachmentColumnDefinition {
  type: "attachment";
  mode: "list" | "gallery";
  photoQualityHD?: boolean;
}
interface AttachmentColumnValue {
  columnType: "attachment";
  value: FirestoreAttachment[] | undefined;
}

export interface CurrencyColumnDefinition {
  type: "currency";
}
export interface CurrencyColumnValue {
  columnType: "currency";
  value: number | undefined;
}

export interface DateColumnDefinition {
  type: "date";
  automation?: {
    behavior: "createdAt";
  };
  displayTime: boolean;
}
export interface DateColumnValue {
  columnType: "date";
  value: FirestoreTimestamp | undefined;
}

export interface GeolocationColumnDefinition {
  type: "geolocation";
}
export interface GeolocationColumnValue {
  columnType: "geolocation";
  value: GeoLocation | undefined;
}

export interface LongTextColumnDefinition {
  type: "longText";
}
export interface LongTextColumnValue {
  columnType: "longText";
  value: string | undefined;
}

export interface ShortTextColumnDefinition {
  type: "shortText";
  automation?: {
    behavior: "autoIncrement";
    prefix: string;
  };
}
export interface ShortTextColumnValue {
  columnType: "shortText";
  value: string | undefined;
}

export interface NumberColumnDefinition {
  type: "number";
  symbol: string;
}

export interface NumberColumnValue {
  columnType: "number";
  value: number | undefined;
}

export interface SelectColumnDefinition {
  type: "select";
  options: {
    [id: string]: {
      index: number;
      label: string;
    };
  };
  allowMultiple: boolean | undefined;
  allowColor: boolean | undefined;
}
export interface SelectColumnValue {
  columnType: "select";
  value: string[] | undefined;
}

export interface CheckboxColumnDefinition {
  isLockingSection?: boolean;
  type: "checkbox";
}
export interface CheckboxColumnValue {
  columnType: "checkbox";
  value: boolean | undefined;
}

export interface UserColumnDefinition {
  type: "user";
  allowMultiple: boolean | undefined;
  automation?: {
    behavior: "createdBy";
  };
}
export interface UserColumnValue {
  columnType: "user";
  value: string[] | undefined;
}

export interface RoomPropertyColumnDefinition {
  type: "roomProperty";
  // TODO remove externalMembers once v64 is release
  property: "name" | "members" | "externalMembers";
}

export interface RoomPropertyColumnValue {
  columnType: "roomProperty";
  value: undefined;
}

export interface SignatureColumnDefinition {
  type: "signature";
}

export interface SignatureColumnValue {
  columnType: "signature";
  value: FirestoreImageAttachment | undefined;
}

export interface JoinColumnDefinition {
  type: "join";
  collection: "form" | "rooms";
}

export interface JoinColumnValue {
  columnType: "join";
  value: undefined;
}

export type FirestoreSchemaColumnDefinition =
  | AttachmentColumnDefinition
  | CurrencyColumnDefinition
  | DateColumnDefinition
  | GeolocationColumnDefinition
  | LongTextColumnDefinition
  | ShortTextColumnDefinition
  | SignatureColumnDefinition
  | SelectColumnDefinition
  | NumberColumnDefinition
  | RoomPropertyColumnDefinition
  | CheckboxColumnDefinition
  | JoinColumnDefinition
  | UserColumnDefinition;

interface FirestoreSchemaColumnValueBase {
  updatedAt?: Date;
  updatedBy?: string;
}
export type FirestoreSchemaColumnValue = FirestoreSchemaColumnValueBase &
  (
    | AttachmentColumnValue
    | CurrencyColumnValue
    | DateColumnValue
    | GeolocationColumnValue
    | LongTextColumnValue
    | ShortTextColumnValue
    | SignatureColumnValue
    | SelectColumnValue
    | NumberColumnValue
    | RoomPropertyColumnValue
    | CheckboxColumnValue
    | JoinColumnValue
    | UserColumnValue
  );

export interface FirestoreSchemaElementDescription {
  text: string;
  image: FirestoreStorageFile | undefined;
  document: FirestoreStorageFile | undefined;
}

interface FirestoreSchemaBaseElement {
  name: string;
  index: number;
  key: string;
  reportKey: string;
  condition: FirestoreCompositeCondition | undefined;
  description: FirestoreSchemaElementDescription | undefined;
}

export interface FirestoreSchemaColumn extends FirestoreSchemaBaseElement {
  elementType: "column";
  definition: FirestoreSchemaColumnDefinition;
  nonDeletable: boolean | undefined;
  nonEditableName: boolean | undefined;
}

export interface FirestoreSchemaSection extends FirestoreSchemaBaseElement {
  elementType: "section";
  color: string;
  elements: Dictionary<FirestoreSchemaSection | FirestoreSchemaColumn>;
}

export type FirestoreSchemaElement =
  | FirestoreSchemaSection
  | FirestoreSchemaColumn;

interface FirestoreBaseSchema {
  collection: string;
  poolId: string;
  name: string;
  rootSection: FirestoreSchemaSection;
  updatedAt: FirestoreTimestamp;
}

export interface FirestoreFolderSchema extends FirestoreBaseSchema {
  index: number;
  description: string;
  autoIncrement: number;
  icon: KSchemaIcon;
  color: string;
  collection: "folder";
  type: "stoppingPoint" | "checkbox" | "custom";
  highlightedCheckbox: string | undefined;
  defaultVisibility: "visible" | "hidden";
}

export interface FirestoreRoomSchema extends FirestoreBaseSchema {
  collection: "room";
  roomCardDisplayColumns: Array<string>;
}

export type FirestoreSchema = FirestoreRoomSchema | FirestoreFolderSchema;

export interface FirestoreSchemaTemplate {
  poolId: string;
  schemaId: string;

  name: string;
  modifiers: Modifier[];
  updatedAt: FirestoreTimestamp;
}

interface FirestoreStandaloneRecordCondition {
  type: "standalone-record";
  columnKey: string;
  predicate: string;
}

export interface FirestoreCoupleRecordCondition {
  type: "couple-record";
  columnKey: string;
  predicate: string;
  value: FirestoreSchemaColumnValue;
}

export interface FirestoreMetadataCondition {
  type: "metadata";
  predicate: string;
  value?: FirestoreSchemaColumnValue;
}

export interface FirestoreStandaloneMetadataCondition {
  type: "standalone-metadata";
  predicate: string;
  value?: FirestoreSchemaColumnValue;
}

export type FirestoreCondition =
  | FirestoreStandaloneRecordCondition
  | FirestoreCoupleRecordCondition
  | FirestoreCompositeCondition
  | FirestoreMetadataCondition
  | FirestoreStandaloneMetadataCondition;

export interface FirestoreCompositeCondition {
  type: "composite";
  operator: "and" | "or";
  conditions: FirestoreCondition[];
}

export interface FirestoreWorkflow {
  poolId: string;
  schemaId: string;

  name: string;
  enabled: boolean;
  condition: FirestoreCompositeCondition;
  actions: Action[];
  createdAt: Workflow["createdAt"];
  updatedAt: Workflow["updatedAt"];
}

export interface FirestoreModularRecord {
  schemaId: string;
  properties: Dictionary<FirestoreSchemaColumnValue>;
}

export interface FirestoreModularFolder extends FirestoreModularRecord {
  poolId: string;
  roomId: string;
  visibility: RoomTypes.RoomVisibility;
  title: string;
  incrementalId: number;
  geolocation: GeoLocation | null | undefined;
  roomSchemaOrder: number;
  updatedAt: FirestoreTimestamp;
}

export interface FirestoreUserUnreadPools {
  updatedAt: FirestoreTimestamp;
  userId: string;
  unreadPools: {
    [poolId: string]: number;
  };
}

export interface FirestoreSchemaView {
  filters: FirestoreCompositeCondition;
  name: string;
  schemaId: string;
  formats: {
    kanban: {
      groupedByProperty: string;
      displayedProperties: string[];
    };
  };
}

interface FirestoreMiniMediaBase {
  createdAt: Date;
  updatedAt: Date;
  roomId: string;
  messageId: string;
}

export interface FirestoreMiniImage extends FirestoreMiniMediaBase {
  type: "image";
  name: string;
  preview: {
    downloadUrl: RemotePath;
    size: ImageSize | undefined;
  };
  geolocation: GeoLocation | undefined;
}

export interface FirestoreMiniVideo extends FirestoreMiniMediaBase {
  type: "video";
  name: string;
}

export interface FirestoreMiniDocument extends FirestoreMiniMediaBase {
  type: "document";
  downloadUrl: RemotePath;
  name: string;
}

export type FirestoreMiniMedia =
  | FirestoreMiniImage
  | FirestoreMiniVideo
  | FirestoreMiniDocument;

export interface FirestoreUserOnboarding {
  shouldShowIntroductionSlides?: boolean;
  shouldShowConversationCreation: boolean;
  shouldShowConversationOnboarding: boolean | undefined;
  shouldShowPoolOnboarding: boolean | undefined;
}

interface FirestoreMapOverlayFile {
  filename: string;
  downloadUrl: RemotePath;
}

export interface FirestoreMapOverlay {
  id: string;
  poolId: string;
  name: string;
  file: FirestoreMapOverlayFile;
  updatedAt: FirestoreTimestamp;
}

export interface FirestoreLibrarySchemaDownloadableFile {
  filename: string;
  downloadUrl: RemotePath;
}

export interface FirestoreLibrarySchemaPresentation {
  description: string;
  coverPicture: FirestoreLibrarySchemaDownloadableFile | null;
  video:
    | {
        type: string;
        url: string;
        id: string;
      }
    | undefined;
  sampleReport: FirestoreLibrarySchemaDownloadableFile | null;
}

export interface FirestoreLibrarySchema {
  id: string;
  definition: FirestoreSchemaSection;
  enabled: boolean;
  name: string;
  icon: KSchemaIcon;
  index: number;
  presentation: FirestoreLibrarySchemaPresentation;
  language: FirestorePoolLanguage;
  reportTemplates: FirestoreLibrarySchemaReportTemplate[];
  updatedAt: FirestoreTimestamp;
  highlightedCheckbox: string | undefined;
  companyId: string;
  tags: Array<{ id: string; name: string }> | undefined;
}

export interface FirestoreRoomSchemaVisibility {
  roomId: string;
  whitelist: string[];
  updatedAt: FirestoreTimestamp;
}

export interface FirestoreCompany {
  id: string;
  name: string;
  color: string;
}

export interface FirestoreDummy {
  id: string;
  name: string;
  updatedAt: FirestoreTimestamp;
  files: Array<FirestoreAttachment>;
}

export interface FirestoreSchemaLibraryTag {
  id: string;
  name: string;
  updatedAt: FirestoreTimestamp;
  language: string;
}

export interface FirestoreSchemaLibraryTags {
  id: string;
  order: Array<FirestoreSchemaLibraryTag>;
}

import { InputPartition } from "@kraaft/shared/core/framework/inputPartition/inputPartitionHelper";
import {
  LocalPath,
  ModernFile,
  RemotePath,
} from "@kraaft/shared/core/modules/file/file";
import { fileHelper } from "@kraaft/shared/core/modules/file/fileHelper/fileHelper.provider";
import { LibrarySchemaLanguage } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { FileMessage } from "@kraaft/shared/core/modules/message/messageState";
import { CompositeCondition } from "@kraaft/shared/core/modules/modularFolder/conditions/conditionTypes";
import { PoolLanguage } from "@kraaft/shared/core/modules/pool/pool";
import {
  PoolMember,
  PoolMembers,
} from "@kraaft/shared/core/modules/poolMember/poolMemberState";
import {
  RoomNotificationFilter,
  RoomVisibility,
} from "@kraaft/shared/core/modules/room/roomState";
import { FirestoreRoomCard } from "@kraaft/shared/core/modules/roomCard/queries/firestore.roomCard";
import {
  KSchemaIcon,
  SchemaDefaultVisibility,
} from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecord } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import {
  KSchemaConversion,
  RawModularRecordProperties,
} from "@kraaft/shared/core/modules/schema/schema.conversion";
import { Modifier } from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateState";
import { toRawModifier } from "@kraaft/shared/core/modules/schemaTemplate/schemaTemplateUtils";
import { UserContactInfos } from "@kraaft/shared/core/modules/userContactInfos/userContactInfo.state";
import { PoolNotificationFilter } from "@kraaft/shared/core/modules/userPool/userPool.state";
import { Action } from "@kraaft/shared/core/modules/workflows/types";
import { WorkflowConversion } from "@kraaft/shared/core/modules/workflows/workflow.conversion";
import {
  httpsCallable,
  httpsCallableRaw,
  httpsCallableUnprefixed,
} from "@kraaft/shared/core/services/firebase/firebaseUtils";
import {
  FirestoreMessage,
  FirestoreRoom,
  FirestoreUserRoom,
  UserPoolRole,
} from "@kraaft/shared/core/services/firestore/firestoreTypes";
import { parseDate } from "@kraaft/shared/core/services/firestore/parseDate";
import { prepareDownloadUrl } from "@kraaft/shared/core/services/firestore/prepareDownloadUrl";
import {
  AnyUnexplained,
  GeoCoordinates,
  GeoLocation,
} from "@kraaft/shared/core/types";
import { uuid } from "@kraaft/shared/core/utils";
import { InvitationTargetParams } from "@kraaft/shared/core/utils/useInviteLinkMessageConfigGenerator/useInviteLinkMessageConfigGenerator.types";
import { SerializedDirectory } from "@kraaft/web/src/core/modules/poolAdmin/poolAdminState";

enum SuccessRate {
  TOTAL = "total",
  PARTIAL = "partial",
  NONE = "none",
}

type ApiKizeoFormExport =
  | {
      type: "defaultPdf";
    }
  | {
      type: "custom";
      docType: "word" | "excel";
      exportId: string;
      name: string;
      pdf: boolean;
    };

const Api = {
  wakeUp: async (): Promise<void> => {
    return (await httpsCallable("wakeUp")()).data;
  },

  getLimitVersion: async (payload: {
    platform: string;
    version: string;
  }): Promise<{
    versionLimitAndroid: string;
    versionLimitIos: string;
  }> => {
    const result = await httpsCallableUnprefixed(
      "versioncheck-getLimitVersion",
    )(payload);
    return result.data;
  },

  /** Bounded Context : User */

  createUser: async (payload: {
    firstName: string;
    lastName: string;
  }): Promise<string> => {
    return (await httpsCallable("createUser")(payload)).data;
  },

  async deleteMe(): Promise<void> {
    await httpsCallable("deleteMe")();
  },

  getAuthenticationFlow: async (
    emailOrPhoneNumber: string,
  ): Promise<{
    user:
      | false
      | {
          shouldResetPassword: boolean;
        };
    provider:
      | false
      | {
          id: string;
          name: string;
          skippable: boolean;
          offlineAccess: boolean;
          helpText: string | undefined;
        };
    linked: boolean;
  }> => {
    const payload = emailOrPhoneNumber.includes("@")
      ? { email: emailOrPhoneNumber }
      : { phoneNumber: emailOrPhoneNumber };

    return (await httpsCallable("getAuthenticationFlow")(payload)).data;
  },

  refreshSsoConnection: async (): Promise<{
    shouldLogout: boolean;
  }> => {
    return (await httpsCallable("refreshSsoConnection")()).data;
  },

  declarePasswordResetted: async (email: string) => {
    return (await httpsCallable("declarePasswordResetted")({ email })).data;
  },

  unlinkSSO: async (userId: string) => {
    return (await httpsCallable("unlinkSSO")({ userId })).data;
  },

  generateAuthenticationDeepLink: async (payload: {
    poolId?: string;
  }): Promise<string> => {
    const result = await httpsCallable("generateAuthenticationDeepLink")(
      payload,
    );
    return result.data;
  },

  getUserContactInfo: async (payload: {
    poolId: string;
    userId: string;
  }): Promise<UserContactInfos> => {
    const result = await httpsCallable("getUserContactInfos")(payload);
    return result.data;
  },

  /** Bounded Context : Workspace */

  generatePoolInviteLinkForStandard: async (payload: {
    poolId: string;
  }): Promise<string> => {
    return (await httpsCallable("generatePoolInviteLinkForStandard")(payload))
      .data;
  },

  editPoolMember: async (payload: {
    userId: string;
    poolId: string;
    update: { role?: UserPoolRole };
  }): Promise<PoolMember> => {
    return (await httpsCallable("editPoolMember")(payload)).data;
  },

  editPoolLanguage: async (payload: {
    poolId: string;
    update: { language: PoolLanguage };
  }): Promise<void> => {
    return (await httpsCallable("editPoolLanguage")(payload)).data;
  },

  changePoolTimeZone: async (payload: {
    poolId: string;
    timeZone: string;
  }): Promise<void> => {
    return (await httpsCallable("changePoolTimeZone")(payload)).data;
  },

  changePoolCurrency: async (payload: {
    poolId: string;
    currencyCode: string;
  }): Promise<void> => {
    return (await httpsCallable("changePoolCurrency")(payload)).data;
  },

  changePoolName: async (payload: {
    poolId: string;
    name: string;
  }): Promise<void> => {
    return (await httpsCallable("changePoolName")(payload)).data;
  },

  getPoolMembers: async (payload: {
    poolId: string;
    counts: boolean;
  }): Promise<PoolMembers> => {
    return (await httpsCallable("getPoolMembers")(payload)).data;
  },

  removeMemberFromPool: async (payload: { poolId: string; userId: string }) => {
    return await httpsCallable("removeMemberFromPool")(payload);
  },

  createPoolLogoUploadPath: async (payload: {
    poolId: string;
    filename: string;
  }): Promise<{ storagePath: string; uploadUrl: string }> => {
    const result = await httpsCallable("createPoolLogoUploadPath")(payload);
    return result.data;
  },

  updatePoolLogo: async (payload: {
    storagePath: string;
    poolId: string;
  }): Promise<RemotePath> => {
    const result = await httpsCallable("updateLogoPool")(payload);
    return result.data;
  },

  /** Bounded Context : Modularity */

  addSchemaTemplate: async (payload: {
    schemaId: string;
    name: string;
    modifiers: Modifier[];
  }): Promise<string> => {
    return (
      await httpsCallable("addSchemaTemplate")({
        schemaId: payload.schemaId,
        name: payload.name,
        modifiers: payload.modifiers.map(toRawModifier),
      })
    ).data;
  },

  editSchemaTemplate: async (payload: {
    id: string;
    name?: string;
    modifiers?: Modifier[];
  }): Promise<void> => {
    return (
      await httpsCallable("editSchemaTemplate")({
        id: payload.id,
        name: payload.name,
        modifiers: payload.modifiers?.map(toRawModifier),
      })
    ).data;
  },

  deleteSchemaTemplate: async (payload: { id: string }): Promise<void> => {
    return (await httpsCallable("deleteSchemaTemplate")(payload)).data;
  },

  renderSchemaTemplate: async (payload: {
    id: string;
  }): Promise<ModularRecord[]> => {
    const data: ModularRecord[] = (
      await httpsCallable("renderSchemaTemplate")(payload)
    ).data;
    for (const d of data) {
      d.id = uuid();
    }
    return data.map(KSchemaConversion.toRecord);
  },

  addSchema: async (payload: {
    poolId: string;
    name: string;
    icon: KSchemaIcon;
  }): Promise<string> => {
    const result = await httpsCallable("addSchema")({
      ...payload,
      color: "#ffffff",
    });
    return result.data;
  },

  editSchema: async (payload: {
    schemaId: string;
    name?: string;
    icon?: KSchemaIcon;
    description?: string;
    roomCardDisplayColumns?: string[];
  }): Promise<{ updatedAt: Date }> => {
    const result = await httpsCallable("editSchema")(payload);
    return { updatedAt: new Date(result.data.updatedAt) };
  },

  deleteSchema: async (payload: {
    requestId: string;
    schemaId: string;
  }): Promise<void> => {
    const result = await httpsCallable("deleteSchema")(payload);
    return result.data;
  },

  reorderSchema: async (
    payload: {
      schemaId: string;
      index: number;
    }[],
  ): Promise<void> => {
    const result = await httpsCallable("reorderSchema")(payload);
    return result.data;
  },

  addSchemaColumn: async (payload: {
    schemaId: string;
    column: {
      name: string;
      index?: number;
      definition: AnyUnexplained;
      containerKey: string;
    };
  }): Promise<{ columnKey: string; updatedAt: Date }> => {
    const data = (
      await httpsCallable("addSchemaColumn")({
        schemaId: payload.schemaId,
        column: {
          name: payload.column.name,
          index: payload.column.index,
          definition: KSchemaConversion.toRawDefinition(
            payload.column.definition,
          ),
          containerKey: payload.column.containerKey,
        },
      })
    ).data;
    return { columnKey: data.columnKey, updatedAt: new Date(data.updatedAt) };
  },

  addSchemaSection: async (payload: {
    schemaId: string;
    name: string;
    index?: number;
    parentSectionKey: string;
  }) => {
    const data = (await httpsCallable("addSchemaSection")(payload)).data;
    return { key: data.key, updatedAt: new Date(data.updatedAt) };
  },

  editSchemaColumn: async (payload: {
    schemaId: string;
    columnKey: string;
    column: {
      name?: string;
      reportKey?: string;
      definition?: AnyUnexplained;
    };
  }): Promise<{ updatedAt: Date }> => {
    const data = (
      await httpsCallable("editSchemaColumn")({
        schemaId: payload.schemaId,
        columnKey: payload.columnKey,
        column: {
          name: payload.column.name,
          reportKey: payload.column.reportKey,
          definition: payload.column.definition
            ? KSchemaConversion.toRawDefinition(payload.column.definition)
            : undefined,
        },
      })
    ).data;
    return { updatedAt: new Date(data.updatedAt) };
  },

  editSchemaSection: async (payload: {
    schemaId: string;
    key: string;
    edits: {
      name?: string;
      color?: string;
    };
  }): Promise<{ updatedAt: Date }> => {
    const data = (
      await httpsCallable("editSchemaSection")({
        schemaId: payload.schemaId,
        key: payload.key,
        name: payload.edits.name,
        color: payload.edits.color,
      })
    ).data;
    return { updatedAt: new Date(data.updatedAt) };
  },

  setPoolSchemaHighlightedCheckbox: async (payload: {
    schemaId: string;
    columnKey: string | undefined;
  }): Promise<{ updatedAt: Date }> => {
    const data = (
      await httpsCallable("setPoolSchemaHighlightedCheckbox")(payload)
    ).data;
    return { updatedAt: new Date(data.updatedAt) };
  },

  moveSchemaElement: async (payload: {
    schemaId: string;
    targetKey: string;
    afterKey?: string;
    sectionKey: string;
  }): Promise<{ updatedAt: Date }> => {
    const data = (await httpsCallable("moveSchemaElement")(payload)).data;
    return { updatedAt: new Date(data.updatedAt) };
  },

  deleteSchemaColumn: async (payload: {
    schemaId: string;
    columnKey: string;
  }): Promise<{ updatedAt: Date }> => {
    const data = (await httpsCallable("deleteSchemaColumn")(payload)).data;
    return { updatedAt: new Date(data.updatedAt) };
  },

  generateDefaultFolderTemplate: async (payload: {
    schemaId: string;
  }): Promise<{
    downloadUrl: RemotePath;
    filename: string;
  }> => {
    const result = await httpsCallable("generateDefaultTemplate")(payload);
    return result.data;
  },

  generateDefaultRoomTemplate: async (payload: {
    schemaId: string;
  }): Promise<{
    downloadUrl: RemotePath;
    filename: string;
  }> => {
    const result = await httpsCallable("generateDefaultRoomTemplate")(payload);

    return result.data;
  },

  /** Bounded Context : Workflow */

  addWorkflow: async (payload: {
    name: string;
    enabled: boolean;
    condition: CompositeCondition;
    actions: Action[];
    schemaId: string;
  }): Promise<string> => {
    const result = await httpsCallable("addWorkflow")({
      name: payload.name,
      enabled: payload.enabled,
      schemaId: payload.schemaId,
      condition: WorkflowConversion.toRawCondition(payload.condition),
      actions: payload.actions,
    });
    return result.data;
  },

  deleteWorkflow: async (payload: { id: string }): Promise<{
    deletedAt: Date;
  }> => {
    const { deletedAt } = (await httpsCallable("deleteWorkflow")(payload)).data;
    return { deletedAt: new Date(deletedAt) };
  },

  editWorkflow: async (payload: {
    id: string;
    name?: string;
    enabled?: boolean;
    condition?: CompositeCondition;
    actions?: Action[];
    schemaId?: string;
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (
      await httpsCallable("editWorkflow")({
        id: payload.id,
        name: payload.name,
        enabled: payload.enabled,
        actions: payload.actions,
        condition: payload.condition
          ? WorkflowConversion.toRawCondition(payload.condition)
          : undefined,
        schemaId: payload.schemaId,
      })
    ).data;

    return { updatedAt: new Date(updatedAt) };
  },

  /** Bounded Context : Analysis */

  addSchemaView: async (payload: {
    name: string;
    schemaId: string;
    filters: CompositeCondition;
    groupedBy?: string | undefined;
    displayedProperties?: string[] | undefined;
  }) => {
    const result = await httpsCallable("addSchemaView")({
      name: payload.name,
      schemaId: payload.schemaId,
      filters: WorkflowConversion.toRawCondition(payload.filters),
      groupedBy: payload.groupedBy,
      displayedProperties: payload.displayedProperties,
    });

    return result.data as string;
  },

  editSchemaView: async (payload: {
    id: string;
    editions: {
      name?: string | undefined;
      filters?: CompositeCondition | undefined;
      groupedByProperty?: string | undefined;
      displayedProperties?: string[] | undefined;
    };
  }) => {
    await httpsCallable("editSchemaView")({
      id: payload.id,
      editions: {
        name: payload.editions.name,
        filters: payload.editions.filters
          ? WorkflowConversion.toRawCondition(payload.editions.filters)
          : undefined,
        groupedByProperty: payload.editions.groupedByProperty,
        displayedProperties: payload.editions.displayedProperties,
      },
    });
  },

  deleteSchemaView: async (payload: { id: string }) => {
    await httpsCallable("deleteSchemaView")(payload);
  },

  /** Bounded Context : Business */

  setKizeoToken: async (payload: {
    poolId: string;
    token: string;
  }): Promise<
    | { errorMessage: string }
    | {
        errorMessage?: never;
        forms: {
          kizeo: { formId: string };
          name: string;
        }[];
      }
  > => {
    return (await httpsCallable("setKizeoToken")(payload)).data;
  },

  loadKizeoForms: async (payload: {
    poolId: string;
  }): Promise<
    | { errorMessage: string }
    | {
        errorMessage?: never;
        forms: {
          kizeo: { formId: string };
          name: string;
        }[];
      }
  > => {
    return (await httpsCallable("loadAllKizeoForms")(payload)).data;
  },

  loadKizeoFormConfigurations: async (payload: {
    poolId: string;
    formIds: string[];
  }): Promise<
    | { errorMessage: string }
    | {
        errorMessage?: never;
        configurations: {
          formId: string;
          isConfigured: boolean;
        }[];
      }
  > => {
    return (await httpsCallable("loadKizeoFormConfigurations")(payload)).data;
  },

  updateKizeoForms: async (payload: {
    poolId: string;
    forms: {
      kizeo: { formId: string };
      name: string;
    }[];
  }): Promise<
    | {
        error: "formNotConfigured";
        forms: { formId: string; name: string }[];
      }
    | {
        error: "writeForbidden";
        forms: { formId: string; name: string }[];
      }
    | {
        error: "kizeoApi";
        message: string;
      }
    | undefined
  > => {
    return (await httpsCallable("updateKizeoForms")(payload)).data;
  },

  loadKizeoFormExports: async (payload: {
    formId: string;
  }): Promise<ApiKizeoFormExport[]> => {
    return (await httpsCallable("loadKizeoFormExports")(payload)).data;
  },

  updateKizeoFormExports: async (payload: {
    formId: string;
    exports: ApiKizeoFormExport[];
  }): Promise<void> => {
    return (await httpsCallable("updateKizeoFormExports")(payload)).data;
  },

  disconnectKizeoForms: async (payload: { poolId: string }) => {
    return (await httpsCallable("disconnectKizeoForms")(payload)).data;
  },

  checkKizeoFormData: async (payload: {
    poolId: string;
    formId: string;
    dataId: string;
  }) => {
    return (await httpsCallable("checkKizeoFormData")(payload)).data;
  },

  addModularFolder: async (payload: {
    roomId: string;
    schemaId: string;
    title?: string;
    messageIds: string[];
  }): Promise<{ successRate: SuccessRate; folderId: string }> => {
    return (await httpsCallable("addModularFolder")(payload)).data;
  },

  addModularFolders: async (payload: {
    requestId: string | undefined;
    roomId: string;
    schemaId: string;
    folders: RawModularRecordProperties[];
  }): Promise<string[]> => {
    return (await httpsCallable("addModularFolders")(payload)).data;
  },

  editModularFolders: async (payload: {
    requestId: string;
    editions: Array<{
      folderId: string;
      properties: RawModularRecordProperties | undefined;
    }>;
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (await httpsCallable("editModularFolders")(payload))
      .data;

    return { updatedAt: new Date(updatedAt) };
  },

  autoRenameModularFolders: async (payload: {
    requestId: string;
    poolId: string;
    schemaId: string;
    prefix: string;
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (
      await httpsCallable("autoRenameModularFolders")(payload)
    ).data;
    return { updatedAt: new Date(updatedAt) };
  },

  deleteModularFolder: async (payload: {
    requestId: string;
    folderId: string;
  }): Promise<void> => {
    return (await httpsCallable("deleteModularFolder")(payload)).data;
  },

  duplicateModularFolder: async (payload: {
    requestId: string;
    folderId: string;
  }): Promise<string> => {
    return (await httpsCallable("duplicateModularFolder")(payload)).data;
  },

  /** Bounded Context : Conversation */

  generateRoomInviteLinkForExternal: async (payload: {
    roomId: string;
  }): Promise<string> => {
    return (
      await httpsCallable("generatePoolAndRoomInviteLinkForExternal")(payload)
    ).data;
  },

  generateRoomInviteLinkForStandard: async (payload: {
    roomId: string;
  }): Promise<string> => {
    return (
      await httpsCallable("generatePoolAndRoomInviteLinkForStandard")(payload)
    ).data;
  },

  checkInviteLink: async (payload: {
    key: string;
  }): Promise<
    | {
        type: "pool";
        poolId: string;
        poolName: string;
        status: "valid";
        identityProvider:
          | {
              id: string;
              name: string;
              offlineAccess: boolean;
            }
          | undefined;
      }
    | {
        type: "room";
        poolId: string;
        roomId: string;
        roomName: string;
        status: "valid";
      }
    | { status: "invalid" | "expired" }
  > => {
    return (await httpsCallable("checkInviteLink")(payload)).data;
  },

  joinInviteLink: async (payload: {
    key: string;
    userId: string;
  }): Promise<void> => {
    return (await httpsCallable("joinInviteLink")(payload)).data;
  },

  toggleRoomVisibility: async (payload: {
    roomId: string;
    visibility: RoomVisibility;
  }) => {
    return await httpsCallable("toggleRoomVisibility")(payload);
  },

  editRoomRecord: async (payload: {
    roomId: string;
    properties: RawModularRecordProperties;
  }) => {
    return await httpsCallable("editRoomRecord")(payload);
  },

  changeRoomUsers: async (payload: {
    roomId: string;
    addedUserIds?: string[];
    removedUserIds?: string[];
  }) => {
    return await httpsCallable("changeRoomUsers")(payload);
  },

  createRoomFromRecord: async (payload: {
    poolId: string;
    recordProperties: RawModularRecordProperties;
    members: string[];
    emoji?: string;
    schemaTemplateIds?: string[];
  }): Promise<string> => {
    const result = await httpsCallable("createRoomFromRecord")(payload);
    return result.data;
  },

  acknowledgePoolOnboarding: async (): Promise<void> => {
    await httpsCallable("acknowledgePoolOnboarding")();
  },

  acknowledgeConversationOnboarding: async (): Promise<void> => {
    await httpsCallable("acknowledgeConversationOnboarding")();
  },

  importRoomFromRecords: async (payload: {
    poolId: string;
    rooms: {
      record: RawModularRecordProperties;
      checkboxes: string[];
      members: string[];
    }[];
  }): Promise<{
    isSuccessful: boolean;
    warnings: string[];
  }> => {
    const result = await httpsCallable("importRoomFromRecords")(payload);
    return result.data;
  },

  setArchive: async (payload: { roomId: string; isArchived: boolean }) => {
    return await httpsCallable("setArchive")(payload);
  },

  archiveRoom: async (payload: {
    roomId: string;
    isArchivedForAll: boolean;
  }) => {
    return await httpsCallable("archiveRoomForAll")(payload);
  },

  setDefaultDirectoryTree: async (payload: {
    poolId: string;
    tree: SerializedDirectory[];
  }) => {
    await httpsCallable("setDefaultDirectoryTree")(payload);
  },

  addDirectoryFilesFromMessages: async (payload: {
    roomId: string;
    directoryId: string;
    messageIds: string[];
  }): Promise<{ successRate: SuccessRate }> => {
    return (await httpsCallable("addDirectoryFilesFromMessages")(payload)).data;
  },

  removeDirectoryFiles: async (payload: {
    roomId: string;
    directoryId: string;
    messageIds: string[];
  }) => {
    return (
      await httpsCallable("removeDirectoryFiles")({
        roomId: payload.roomId,
        references: payload.messageIds.map((messageId) => ({
          directoryId: payload.directoryId,
          messageId,
        })),
      })
    ).data;
  },

  downloadDirectory: async (payload: {
    roomId: string;
    directoryId: string;
  }): Promise<{ downloadUrl: RemotePath; filename: string }> => {
    const result = await httpsCallable("downloadDirectoryContent")(payload);
    return result.data;
  },

  addDirectoryFiles: async (payload: {
    roomId: string;
    directoryId: string;
    files: {
      storagePath: string;
      filename: string;
    }[];
  }): Promise<{ successRate: SuccessRate }> => {
    return (await httpsCallable("addDirectoryFiles")(payload)).data;
  },

  addDirectory: async (payload: {
    roomId: string;
    name: string;
    parentId: string;
  }) => {
    await httpsCallable("addDirectory")(payload);
  },

  removeDirectory: async (payload: { roomId: string; directoryId: string }) => {
    await httpsCallable("removeDirectory")(payload);
  },

  renameDirectory: async (payload: {
    roomId: string;
    directoryId: string;
    newDirectoryName: string;
  }) => {
    await httpsCallable("renameDirectory")(payload);
  },

  moveDirectory: async (payload: {
    roomId: string;
    directoryId: string;
    newParentId: string;
    afterSiblingId?: string;
  }) => {
    await httpsCallable("moveDirectory")(payload);
  },

  /** Bounded Context : Message */

  forwardMessageInRoom: async (payload: {
    roomId: string;
    messageId: string;
    destRoomId: string;
    optimisticId: string;
    preserveCaptions: boolean;
  }): Promise<void> => {
    const result = await httpsCallable("forwardMessageInRoom")(payload);
    return result.data;
  },

  editMessageText: async (payload: {
    roomId: string;
    messageId: string;
    text: InputPartition[];
  }) => {
    return await httpsCallable("editMessageText")(payload);
  },

  editMessageCaption: async (payload: {
    roomId: string;
    messageId: string;
    caption: InputPartition[];
  }) => {
    return await httpsCallable("editMessageCaption")(payload);
  },

  editMessageTranscription: async (payload: {
    roomId: string;
    messageId: string;
    transcription: InputPartition[];
  }) => {
    return await httpsCallable("editMessageTranscription")(payload);
  },

  unflagModularFoldersMessage: async (payload: {
    roomId: string;
    messageId: string;
  }) => {
    return await httpsCallable("unflagModularFoldersMessage")(payload);
  },

  addModularFolderAttachmentFromMessage: async (payload: {
    roomId: string;
    folderId: string;
    messageId: string;
    columnKey: string | undefined;
  }): Promise<{
    successRate: SuccessRate;
    updatedAt: Date;
  }> => {
    const { data } = await httpsCallable(
      "addModularFolderAttachmentFromMessage",
    )(payload);
    return {
      successRate: data.successRate,
      updatedAt: new Date(data.updatedAt),
    };
  },

  addModularFolderAttachmentsFromMessages: async (payload: {
    roomId: string;
    folderId: string;
    messageIds: string[];
    columnKey: string | undefined;
  }): Promise<{ successRate: SuccessRate; updatedAt: Date }> => {
    const { data } = await httpsCallable(
      "addModularFolderAttachmentsFromMessages",
    )(payload);
    return {
      successRate: data.successRate,
      updatedAt: new Date(data.updatedAt),
    };
  },

  addModularFolderAttachments: async (payload: {
    requestId: string;
    folderId: string;
    columnKey: string | undefined;
    attachments: (
      | {
          storagePath: string;
          filename: string;
          text: InputPartition[] | undefined;
          coords:
            | {
                latitude: number;
                longitude: number;
              }
            | undefined;
        }
      | {
          storagePath: string;
          filename: string;
        }
    )[];
  }): Promise<{ updatedAt: Date; ids: string[] }> => {
    const result = (await httpsCallable("addModularFolderAttachments")(payload))
      .data;
    return {
      updatedAt: new Date(result.updatedAt),
      ids: result.ids,
    };
  },

  deleteModularFolderAttachment: async (payload: {
    requestId: string;
    folderId: string;
    documentId: string;
    columnKey: string | undefined;
  }) => {
    const { updatedAt } = (
      await httpsCallable("deleteModularFolderAttachment")(payload)
    ).data;
    return { updatedAt: new Date(updatedAt) };
  },

  removeMessage: async (payload: { messageId: string }) => {
    return await httpsCallable("removeMessage")(payload);
  },

  acknowledgeReading: async (payload: {
    messageId: string;
    roomId: string;
  }): Promise<void> => {
    const result = await httpsCallable("acknowledgeReading")(payload);
    return result.data;
  },

  addAudioMessageAtomically: async (payload: {
    roomId: string;
    content: {
      filename: string;
      storagePath: string;
      optimisticId: string;
    };
    answerTo?: string | undefined;
  }): Promise<{ messageId: string; storagePath: string }> => {
    const result = await httpsCallable("addAudioMessageAtomically")(payload);
    return result.data;
  },

  createFileMessageUploadPath: async (payload: {
    roomId: string;
    filename: string;
    answerTo?: string | undefined;
  }): Promise<{ storagePath: string; uploadUrl: string }> => {
    const result = await httpsCallable("createFileMessageUploadPath")(payload);
    return result.data;
  },

  createFolderUploadPaths: async (payload: {
    roomId: string;
    filenames: string[];
  }): Promise<{ storagePath: string; uploadUrl: string }[]> => {
    const result = await httpsCallable("createFolderUploadPaths")(payload);
    return result.data;
  },

  addFileMessageAtomically: async (payload: {
    roomId: string;
    content: {
      filename: string;
      storagePath: string;
      optimisticId: string;
      caption: InputPartition[] | undefined;
      coords:
        | {
            latitude: number;
            longitude: number;
          }
        | undefined;
    };
    answerTo?: string | undefined;
  }): Promise<void> => {
    const result = await httpsCallable("addFileMessageAtomically")(payload);
    return result.data;
  },

  addTextMessage: async (payload: {
    roomId: string;
    text: InputPartition[];
    optimisticId: string;
    answerTo?: string | undefined;
  }): Promise<{ id: string; data: FirestoreMessage }> => {
    const result = await httpsCallable("addTextMessage")(payload);
    return result.data;
  },

  addGeolocationMessage: async (payload: {
    roomId: string;
    geolocation: GeoLocation;
    optimisticId: string;
    answerTo?: string | undefined;
  }): Promise<{ id: string; data: FirestoreMessage }> => {
    const result = await httpsCallable("addGeolocationMessage")(payload);
    return result.data;
  },

  reactToMessage: async (payload: {
    roomId: string;
    messageId: string;
    emoji: string;
  }) => {
    const result = await httpsCallable("reactToMessage")(payload);

    return result.data;
  },

  unreactToMessage: async (payload: { roomId: string; messageId: string }) => {
    const result = await httpsCallable("unreactToMessage")(payload);

    return result.data;
  },

  updateMessageAttachmentGeolocation: async (payload: {
    roomId: string;
    messageId: string;
    geolocation: GeoLocation | undefined;
  }) => {
    return await httpsCallable("updateMessageAttachmentGeolocation")(payload);
  },

  updateModularFolderAttachmentGeolocation: async (payload: {
    requestId: string;
    folderId: string;
    attachmentId: string;
    geolocation: GeoLocation | undefined;
  }) => {
    const { updatedAt } = (
      await httpsCallable("updateModularFolderAttachmentGeolocation")(payload)
    ).data;
    return { updatedAt: new Date(updatedAt) };
  },

  sendOneshotImage: async (payload: {
    roomId: string;
    optimisticId: string;
    answerTo: string | undefined;
    content: {
      file: ModernFile<LocalPath>;
      caption: InputPartition[] | undefined;
      coords: GeoCoordinates | undefined;
    };
  }) => {
    const data = new FormData();

    data.append(
      "data",
      JSON.stringify({
        roomId: payload.roomId,
        optimisticId: payload.optimisticId,
        answerTo: payload.answerTo,
        caption: payload.content.caption,
        coords: payload.content.coords,
      }),
    );

    await fileHelper.addFileToFormData(payload.content.file, data);
    const result = await httpsCallableRaw("sendOneshotImage")(data);

    return result.data;
  },

  downloadFileMessages: async (payload: {
    roomId: string;
    messageIds: string[];
    type: FileMessage["type"] | undefined;
  }): Promise<{ downloadUrl: RemotePath; storagePath: string }> => {
    const result = await httpsCallable("downloadFileMessages")(payload);
    return result.data;
  },

  reportViolatingMessage: async (payload: {
    roomId: string;
    messageId: string;
  }): Promise<void> => {
    const result = await httpsCallable("reportViolatingMessage")(payload);
    return result.data;
  },

  /** Bounded Context : Export */

  generateReport: async (payload: {
    poolId: string;
    includeArchive: boolean | undefined;
    dateRange: { start: string; end: string } | undefined;
  }): Promise<{
    downloadUrl: RemotePath;
  }> => {
    const result = await httpsCallable("generateUserExport")(payload);
    return result.data;
  },

  sendRoomReport: async (payload: {
    roomId: string;
    email: string;
    filters?: RoomReportFiltersJson;
    templateId?: string;
  }): Promise<void> => {
    const result = await httpsCallable("sendRoomReport")(payload);
    return result.data;
  },

  generateRoomReport: async (payload: {
    roomId: string;
    filters: RoomReportFiltersJson;
    templateId?: string;
  }): Promise<{ downloadUrl: RemotePath; filename: string }> => {
    const result = await httpsCallable("generateRoomReport")(payload);
    return result.data;
  },

  generateModularFolderReport: async (payload: {
    folderId: string;
    templateId?: string;
  }) => {
    const data: { downloadUrl: RemotePath; filename: string } = (
      await httpsCallable("generateModularFolderReport")(payload)
    ).data;
    return {
      filename: data.filename,
      downloadUrl: prepareDownloadUrl(data.downloadUrl),
    };
  },

  generateModularFolderPDFReport: async (payload: {
    folderId: string;
    templateId?: string;
  }): Promise<{ downloadUrl: RemotePath; filename: string }> => {
    return (await httpsCallable("generateModularFolderPDFReport")(payload))
      .data;
  },

  exportModularFolderFiles: async (payload: {
    folderId: string;
  }): Promise<{ downloadUrl: RemotePath; filename: string }> => {
    return (await httpsCallable("exportModularFolderFiles")(payload)).data;
  },

  exportXlsFromModularFolders: async (payload: {
    schemaId: string;
    filters: CompositeCondition | undefined;
  }): Promise<{ downloadUrl: RemotePath; filename: string }> => {
    const result = await httpsCallable("exportXlsFromModularFolders")({
      schemaId: payload.schemaId,
      filters: payload.filters
        ? WorkflowConversion.toRawCondition(payload.filters)
        : undefined,
    });
    return result.data;
  },

  bulkExportModularFoldersInRoom: async (payload: {
    requestId: string;
    modularFolderIds: string[];
    templateId: string;
    schemaId: string;
    destinationEmail: string;
    asPdf: boolean;
    roomId: string;
  }) => {
    await httpsCallable("bulkExportModularFoldersInRoom")(payload);
  },

  synchronizeRoomWithSharepoint: async (payload: {
    roomId: string;
    sharepointUrl: string;
  }): Promise<{
    data: { error: "unauthorized" | "invalid url" | "unknown" };
  }> => httpsCallable("synchronizeRoomWithSharepoint")(payload),

  stopSynchronizeRoomWithSharepoint: async (payload: {
    roomId: string;
  }): Promise<{ data: unknown }> =>
    httpsCallable("stopSynchronizeRoomWithSharepoint")(payload),

  synchronizePoolWithSharepoint: async (payload: {
    poolId: string;
    sharepointUrl: string;
  }): Promise<{
    data: { error: "unauthorized" | "invalid url" | "unknown" };
  }> => httpsCallable("synchronizePoolWithSharepoint")(payload),

  stopSynchronizePoolWithSharepoint: async (payload: {
    poolId: string;
  }): Promise<{ data: unknown }> =>
    httpsCallable("stopSynchronizePoolWithSharepoint")(payload),

  getPoolMicrosoftStorageData: async (payload: {
    poolId: string;
  }): Promise<{
    data: { owner: string; status: "valid" | "invalid"; synchronizedAt: Date };
  }> => {
    const result = await httpsCallable("getPoolMicrosoftStorageData")(payload);

    return {
      data: {
        ...result.data,
        synchronizedAt: parseDate(result.data.synchronizedAt),
      },
    };
  },

  disconnectMicrosoftStorageFromPool: async (payload: {
    poolId: string;
  }): Promise<{ data: unknown }> =>
    httpsCallable("disconnectMicrosoftStorageFromPool")(payload),

  /** Bounded Context : TODO */

  toggleInboundEmail: async (payload: {
    poolId: string;
    enabled: boolean;
    inboundEmailId?: string;
  }): Promise<void> => {
    const result = await httpsCallable("toggleInboundEmail")(payload);
    return result.data;
  },

  enableDeliveryNote: async (payload: {
    poolId: string;
  }): Promise<void> => {
    await httpsCallable("enableDeliveryNote")(payload);
  },

  disableDeliveryNote: async (payload: {
    poolId: string;
  }): Promise<void> => {
    await httpsCallable("disableDeliveryNote")(payload);
  },

  loadRoomPage: async (payload: {
    cursor:
      | {
          lastEventAt: Date;
          roomId: string;
        }
      | undefined;
    filters: {
      poolId: string;
      visibility: "member" | "all" | "superadmin";
      statusId: string | undefined;
      labelIds: string[];
      responsibleId: string | undefined;
      showArchived: boolean;
      searchText?: string | undefined;
    };
    limit: number | undefined;
  }): Promise<{
    roomCards: {
      id: string;
      data: { room: FirestoreRoom; userRoom: FirestoreUserRoom | undefined };
    }[];
    hasMore: boolean;
    cursor: { roomId: string; lastEventAt: Date } | undefined;
  }> => {
    const result = await httpsCallable("loadRoomPage")(payload);

    return result.data;
  },

  sendSmsInvites: async (payload: {
    recipientPhoneNumbers: string[];
    invitationTarget: InvitationTargetParams;
    regionCode: string;
  }): Promise<void> => (await httpsCallable("sendSmsInvites")(payload)).data,

  /** Bounded Context : TODO Legacy / Deprecated */

  createOwnWorkspace: async (
    name: string,
    language: string,
    timeZone: string,
  ): Promise<string> => {
    const result = await httpsCallable("createOwnWorkspace")({
      name,
      language,
      timeZone,
    });
    return result.data;
  },

  isWorkspaceNameTaken: async (name: string): Promise<boolean> => {
    const result = await httpsCallable("isWorkspaceNameTaken")({ name });
    return result.data;
  },

  setUserJob: async (job: string) => {
    await httpsCallable("setUserJob")({ job });
  },

  superadminSetUserJob: async (userId: string, job: string) => {
    await httpsCallable("superadminSetUserJob")({ userId, job });
  },

  importUsersFromTable: async (
    poolId: string,
    users: {
      firstName: string | undefined;
      lastName: string | undefined;
      email: string | undefined;
      phoneNumber: string | undefined;
      role: string | undefined;
    }[],
    notifyByEmail: boolean,
    notifyBySMS: boolean,
  ) => {
    return (await httpsCallable("importUsersFromTable")({
      poolId,
      rows: users,
      notifyByEmail,
      notifyBySMS,
    })) as { data: { warnings: string[] | null } };
  },

  editUserAuth: async (payload: {
    userId: string;
    email?: string;
    phoneNumber?: string | null;
    firstName?: string;
    lastName?: string;
  }): Promise<
    | { success: true }
    | {
        success: false;
        emailConflict?: boolean;
        phoneNumberConflict?: boolean;
        message?: string;
      }
  > => {
    return (await httpsCallable("editUserAuth")(payload)).data;
  },

  superAdminEditUserAuth: async (payload: {
    userId: string;
    email?: string | null;
    phoneNumber?: string | null;
    firstName?: string;
    lastName?: string;
    force: boolean;
  }): Promise<
    | { success: true }
    | {
        success: false;
        emailConflict?: { userId: string };
        phoneNumberConflict?: { userId: string };
        message?: string;
      }
  > => {
    return (await httpsCallable("superAdminEditUserAuth")(payload)).data;
  },

  identifyUser: () => httpsCallable("identifyUser")(),

  changePoolNotificationFilter: async ({
    poolId,
    notificationFilter,
  }: {
    poolId: string;
    notificationFilter: PoolNotificationFilter;
  }) => {
    return (
      await httpsCallable("changePoolNotificationSource")({
        poolId,
        allowedNotificationSource: notificationFilter,
      })
    ).data;
  },

  changeRoomNotificationFilter: async ({
    roomId,
    notificationFilter,
  }: {
    roomId: string;
    notificationFilter: RoomNotificationFilter;
  }) => {
    return (
      await httpsCallable("changeRoomNotificationSource")({
        roomId,
        allowedNotificationSource: notificationFilter,
      })
    ).data;
  },

  addSignatureToModularFolder: async (payload: {
    folderId: string;
    columnKey: string | undefined;
    file: ModernFile<LocalPath>;
  }) => {
    const data = new FormData();

    data.append(
      "data",
      JSON.stringify({
        folderId: payload.folderId,
        columnKey: payload.columnKey,
      }),
    );

    await fileHelper.addFileToFormData(payload.file, data);
    const {
      response: { updatedAt },
    } = (await httpsCallableRaw("addSignatureToModularFolder")(data)).data;
    return { updatedAt: new Date(updatedAt) };
  },

  sendPromotionalEmailForWeb: async (payload: {
    poolId: string;
    email: string;
  }) => {
    await httpsCallable("sendPromotionalEmailForWeb")(payload);
  },

  generateReportTemplateUploadPath: async (payload: {
    poolId: string;
    filename: string;
  }): Promise<{ storagePath: string; uploadUrl: string }> => {
    const result = await httpsCallable("generateReportTemplateUploadPath")(
      payload,
    );
    return result.data;
  },

  addReportTemplate: async (payload: {
    poolId: string;
    schemaId: string;
    name: string;
    storagePath: string;
    forceAdd: boolean;
  }): Promise<
    | { success: true }
    | {
        success: false;
        unknownTags?: string[];
      }
  > => {
    return (await httpsCallable("addReportTemplate")(payload)).data;
  },

  editReportTemplate: async (payload: {
    reportTemplateId: string;
    editions: { name?: string; enabled?: boolean };
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (await httpsCallable("editReportTemplate")(payload))
      .data;

    return { updatedAt: new Date(updatedAt) };
  },

  editCustomReportTemplateFile: async (payload: {
    reportTemplateId: string;
    storagePath: string;
    forceAdd: boolean;
  }): Promise<
    | { success: true }
    | {
        success: false;
        unknownTags?: string[];
      }
  > => {
    return (await httpsCallable("editCustomReportTemplateFile")(payload)).data;
  },

  reorderReportTemplate: async (payload: {
    poolId: string;
    editions: { [reportTemplateId: string]: { index: number } };
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (
      await httpsCallable("reorderReportTemplate")(payload)
    ).data;

    return { updatedAt: new Date(updatedAt) };
  },

  deleteReportTemplate: async (payload: {
    reportTemplateId: string;
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (await httpsCallable("deleteReportTemplate")(payload))
      .data;

    return { updatedAt: new Date(updatedAt) };
  },

  addIdentityProvider: async (payload: {
    id: string;
    name: string;
    domains: string[];
    skippable: boolean;
    offlineAccess: string | undefined;
    helpText: string | undefined;
    subConfigs:
      | {
          id: string;
          name: string;
          domains: string[];
          skippable: boolean;
          helpText: string | undefined;
        }[]
      | undefined;
  }) => {
    await httpsCallable("addIdentityProvider")(payload);
  },

  editIdentityProvider: async (payload: {
    id: string;
    name: string;
    domains: string[];
    skippable: boolean;
    offlineAccess: string | undefined;
    helpText: string | undefined;
    subConfigs:
      | {
          id: string;
          name: string;
          domains: string[];
          skippable: boolean;
          helpText: string | undefined;
        }[]
      | undefined;
  }) => {
    await httpsCallable("editIdentityProvider")(payload);
  },

  setPoolIdentityProvider: async (payload: {
    poolId: string;
    providerId: string | undefined;
    subConfigId: string | undefined;
  }) => {
    await httpsCallable("setPoolIdentityProvider")(payload);
  },

  setPoolCompanySize: async (poolId: string, companySize: string) => {
    await httpsCallable("setPoolCompanySize")({ poolId, companySize });
  },

  setPoolCompanyIndustry: async (poolId: string, companyIndustry: string) => {
    await httpsCallable("setPoolCompanyIndustry")({ poolId, companyIndustry });
  },

  setPoolAcquisitionSource: async (
    poolId: string,
    acquisitionSource: string,
  ) => {
    await httpsCallable("setPoolAcquisitionSource")({
      poolId,
      acquisitionSource,
    });
  },

  generateMapOverlayUploadPath: async (payload: {
    poolId: string;
    filename: string;
  }): Promise<{ storagePath: string; uploadUrl: string }> => {
    const result = await httpsCallable("generateMapOverlayUploadPath")(payload);
    return result.data;
  },

  addMapOverlay: async (payload: {
    poolId: string;
    name: string;
    file: File;
  }) => {
    const data = new FormData();

    data.append(
      "data",
      JSON.stringify({
        poolId: payload.poolId,
        name: payload.name,
      }),
    );

    data.append(payload.file.name, payload.file);

    return (await httpsCallableRaw("addMapOverlay")(data)).data;
  },

  deleteMapOverlay: async (payload: {
    mapOverlayId: string;
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (await httpsCallable("deleteMapOverlay")(payload))
      .data;

    return { updatedAt: new Date(updatedAt) };
  },

  deleteRoom: async (payload: { roomId: string }): Promise<void> => {
    await httpsCallable("deleteRoom")(payload);
  },

  addLibrarySchema: async (payload: {
    language: LibrarySchemaLanguage;
    name: string;
    icon: KSchemaIcon;
  }): Promise<{ librarySchemaId: string }> => {
    const librarySchemaId = (await httpsCallable("addLibrarySchema")(payload))
      .data;

    return { librarySchemaId };
  },

  editLibrarySchema: async (payload: {
    librarySchemaId: string;
    update: { enabled?: boolean };
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (await httpsCallable("editLibrarySchema")(payload))
      .data;

    return { updatedAt: new Date(updatedAt) };
  },

  reorderLibrarySchemas: async (payload: {
    language: LibrarySchemaLanguage;
    orderedIds: string[];
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (
      await httpsCallable("reorderLibrarySchemas")(payload)
    ).data;

    return { updatedAt: new Date(updatedAt) };
  },

  setLibrarySchemaPresentationDescription: async (payload: {
    librarySchemaId: string;
    description: string;
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (
      await httpsCallable("setLibrarySchemaPresentationDescription")(payload)
    ).data;

    return { updatedAt: new Date(updatedAt) };
  },

  setLibrarySchemaPresentationVideo: async (payload: {
    librarySchemaId: string;
    videoUrl?: string;
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (
      await httpsCallable("setLibrarySchemaPresentationVideo")(payload)
    ).data;

    return { updatedAt: new Date(updatedAt) };
  },

  editLibrarySchemaPresentationCoverPicture: async (payload: {
    librarySchemaId: string;
    coverPictureFile: ModernFile<LocalPath> | null;
  }): Promise<{ updatedAt: Date }> => {
    const data = new FormData();

    data.append(
      "data",
      JSON.stringify({
        librarySchemaId: payload.librarySchemaId,
        hasCoverPictureFile: Boolean(payload.coverPictureFile),
      }),
    );

    if (payload.coverPictureFile) {
      await fileHelper.addFileToFormData(payload.coverPictureFile, data);
    }

    const { updatedAt } = (
      await httpsCallableRaw("editLibrarySchemaPresentationCoverPicture")(data)
    ).data;

    return { updatedAt: new Date(updatedAt) };
  },

  editLibrarySchemaPresentationSampleReport: async (payload: {
    librarySchemaId: string;
    sampleReportFile: ModernFile<LocalPath> | null;
  }): Promise<{ updatedAt: Date }> => {
    const data = new FormData();

    data.append(
      "data",
      JSON.stringify({
        librarySchemaId: payload.librarySchemaId,
        hasSampleReportFile: Boolean(payload.sampleReportFile),
      }),
    );

    if (payload.sampleReportFile) {
      await fileHelper.addFileToFormData(payload.sampleReportFile, data);
    }

    const { updatedAt } = (
      await httpsCallableRaw("editLibrarySchemaPresentationSampleReport")(data)
    ).data;

    return { updatedAt: new Date(updatedAt) };
  },

  deleteLibrarySchema: async (payload: {
    librarySchemaId: string;
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (await httpsCallable("deleteLibrarySchema")(payload))
      .data;

    return { updatedAt: new Date(updatedAt) };
  },

  duplicateLibrarySchema: async (payload: {
    librarySchemaId: string;
    targetLanguage: LibrarySchemaLanguage;
  }): Promise<{ librarySchemaId: string }> => {
    const librarySchemaId = (
      await httpsCallable("duplicateLibrarySchema")(payload)
    ).data;

    return { librarySchemaId };
  },

  copySchemaInLibrary: async (payload: {
    schemaId: string;
  }): Promise<{ librarySchemaId: string }> => {
    const librarySchemaId = (
      await httpsCallable("copySchemaInLibrary")(payload)
    ).data;

    return { librarySchemaId: librarySchemaId };
  },

  useLibrarySchema: async (payload: {
    librarySchemaId: string;
    poolId: string;
  }): Promise<{ schemaId: string }> => {
    const schemaId = (await httpsCallable("useLibrarySchema")(payload)).data;

    return { schemaId };
  },

  editLibrarySchemaMetadata: async (payload: {
    librarySchemaId: string;
    update: { name?: string; icon?: KSchemaIcon };
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (
      await httpsCallable("editLibrarySchemaMetadata")(payload)
    ).data;

    return { updatedAt: new Date(updatedAt) };
  },

  setLibrarySchemaHighlightedCheckbox: async (payload: {
    schemaId: string;
    columnKey: string | undefined;
  }): Promise<{ updatedAt: Date }> => {
    const data = (
      await httpsCallable("setLibrarySchemaHighlightedCheckbox")(payload)
    ).data;
    return { updatedAt: new Date(data.updatedAt) };
  },

  editLibrarySchemaColumn: async (payload: {
    librarySchemaId: string;
    columnKey: string;
    update: {
      name?: string;
      definition?: AnyUnexplained;
    };
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (
      await httpsCallable("editLibrarySchemaColumn")({
        librarySchemaId: payload.librarySchemaId,
        columnKey: payload.columnKey,
        update: {
          name: payload.update.name,
          definition: payload.update.definition
            ? KSchemaConversion.toRawDefinition(payload.update.definition)
            : undefined,
        },
      })
    ).data;

    return { updatedAt: new Date(updatedAt) };
  },

  editLibrarySchemaSection: async (payload: {
    librarySchemaId: string;
    sectionKey: string;
    update: {
      name?: string;
      color?: string;
    };
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (
      await httpsCallable("editLibrarySchemaSection")(payload)
    ).data;

    return { updatedAt: new Date(updatedAt) };
  },

  moveLibrarySchemaElement: async (payload: {
    librarySchemaId: string;
    targetKey: string;
    afterKey?: string;
    sectionKey: string;
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (
      await httpsCallable("moveLibrarySchemaElement")(payload)
    ).data;

    return { updatedAt: new Date(updatedAt) };
  },

  addLibrarySchemaColumn: async (payload: {
    librarySchemaId: string;
    name: string;
    definition: AnyUnexplained;
    index?: number;
    parentSectionKey: string;
  }) => {
    const { columnKey, updatedAt } = (
      await httpsCallable("addLibrarySchemaColumn")({
        librarySchemaId: payload.librarySchemaId,
        name: payload.name,
        index: payload.index,
        definition: KSchemaConversion.toRawDefinition(payload.definition),
        parentSectionKey: payload.parentSectionKey,
      })
    ).data;

    return { columnKey, updatedAt: new Date(updatedAt) };
  },

  addLibrarySchemaSection: async (payload: {
    librarySchemaId: string;
    name: string;
    index?: number;
    parentSectionKey: string;
  }) => {
    const { key, updatedAt } = (
      await httpsCallable("addLibrarySchemaSection")(payload)
    ).data;

    return { key, updatedAt: new Date(updatedAt) };
  },

  deleteLibrarySchemaColumn: async (payload: {
    librarySchemaId: string;
    columnKey: string;
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (
      await httpsCallable("deleteLibrarySchemaColumn")(payload)
    ).data;

    return { updatedAt: new Date(updatedAt) };
  },

  generateLibrarySchemaReportTemplateUploadPath: async (payload: {
    librarySchemaId: string;
    filename: string;
  }): Promise<{ storagePath: string; uploadUrl: string }> => {
    const result = await httpsCallable(
      "generateLibrarySchemaReportTemplateUploadPath",
    )(payload);
    return result.data;
  },

  addLibrarySchemaReportTemplate: async (payload: {
    librarySchemaId: string;
    name: string;
    storagePath: string;
    forceAdd: boolean;
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (
      await httpsCallable("addLibrarySchemaReportTemplate")(payload)
    ).data;

    return { updatedAt: new Date(updatedAt) };
  },

  reorderLibrarySchemaReportTemplates: async (payload: {
    librarySchemaId: string;
    reportTemplateIds: string[];
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (
      await httpsCallable("reorderLibrarySchemaReportTemplates")(payload)
    ).data;

    return { updatedAt: new Date(updatedAt) };
  },

  toggleLibrarySchemaReportTemplate: async (payload: {
    librarySchemaId: string;
    reportTemplateId: string;
    enabled: boolean;
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (
      await httpsCallable("toggleLibrarySchemaReportTemplate")(payload)
    ).data;

    return { updatedAt: new Date(updatedAt) };
  },

  renameLibrarySchemaReportTemplate: async (payload: {
    librarySchemaId: string;
    reportTemplateId: string;
    name: string;
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (
      await httpsCallable("renameLibrarySchemaReportTemplate")(payload)
    ).data;

    return { updatedAt: new Date(updatedAt) };
  },

  replaceFileLibrarySchemaReportTemplate: async (payload: {
    librarySchemaId: string;
    reportTemplateId: string;
    storagePath: string;
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (
      await httpsCallable("replaceFileLibrarySchemaReportTemplate")(payload)
    ).data;

    return { updatedAt: new Date(updatedAt) };
  },

  removeLibrarySchemaReportTemplate: async (payload: {
    librarySchemaId: string;
    reportTemplateId: string;
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (
      await httpsCallable("removeLibrarySchemaReportTemplate")(payload)
    ).data;

    return { updatedAt: new Date(updatedAt) };
  },

  countModularFoldersForSchema: async (payload: {
    schemaId: string;
  }): Promise<{ count: number }> => {
    return (await httpsCallable("countModularFoldersForSchema")(payload)).data;
  },

  setOwnMail: async (payload: {
    mail: string;
  }): Promise<{ success: true; newAuthToken: string }> => {
    return (await httpsCallable("setOwnMail")(payload)).data;
  },

  editElementCondition: async (payload: {
    schemaId: string;
    key: string;
    condition: CompositeCondition | undefined;
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (
      await httpsCallable("editElementCondition")({
        schemaId: payload.schemaId,
        key: payload.key,
        condition: payload.condition
          ? WorkflowConversion.toRawCondition(payload.condition)
          : undefined,
      })
    ).data;
    return { updatedAt: new Date(updatedAt) };
  },

  duplicateElement: async (payload: {
    schemaId: string;
    key: string;
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (
      await httpsCallable("duplicateElement")({
        schemaId: payload.schemaId,
        key: payload.key,
      })
    ).data;
    return { updatedAt: new Date(updatedAt) };
  },

  duplicateLibrarySchemaElement: async (payload: {
    schemaId: string;
    key: string;
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (
      await httpsCallable("duplicateLibrarySchemaElement")({
        schemaId: payload.schemaId,
        key: payload.key,
      })
    ).data;
    return { updatedAt: new Date(updatedAt) };
  },

  editLibrarySchemaElementCondition: async (payload: {
    schemaId: string;
    key: string;
    condition: CompositeCondition | undefined;
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (
      await httpsCallable("editLibrarySchemaElementCondition")({
        schemaId: payload.schemaId,
        key: payload.key,
        condition: payload.condition
          ? WorkflowConversion.toRawCondition(payload.condition)
          : undefined,
      })
    ).data;
    return { updatedAt: new Date(updatedAt) };
  },

  showSchemaForRoom: async (payload: {
    roomId: string;
    schemaId: string;
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (await httpsCallable("showSchemaForRoom")(payload))
      .data;

    return { updatedAt: new Date(updatedAt) };
  },

  hideSchemaForRoom: async (payload: {
    roomId: string;
    schemaId: string;
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (await httpsCallable("hideSchemaForRoom")(payload))
      .data;

    return { updatedAt: new Date(updatedAt) };
  },

  setRoomLocationFromMessage: async (payload: {
    roomId: string;
    geolocation: GeoLocation;
  }) => {
    await httpsCallable("setRoomLocationFromMessage")(payload);
  },

  getBillingInformation: async (payload: {
    poolId: string;
  }): Promise<GetBillingInformationResult> => {
    return (await httpsCallable("getBillingInformation")(payload)).data;
  },

  setPoolTrial: async (payload: {
    poolId: string;
    trialEnd?: Date;
  }): Promise<void> => {
    await httpsCallable("setPoolTrial")({
      poolId: payload.poolId,
      trialEnd: payload.trialEnd ? payload.trialEnd.toISOString() : undefined,
    });
  },

  setPoolRestrictionScheme: async (payload: {
    poolId: string;
    restrictionScheme: "restricted" | "unrestricted" | "automatic";
  }): Promise<void> => {
    await httpsCallable("setPoolRestrictionScheme")(payload);
  },

  setPoolStripeSubscriptionId: async (payload: {
    poolId: string;
    stripeSubscriptionId?: string;
  }): Promise<void> => {
    await httpsCallable("setPoolStripeSubscriptionId")(payload);
  },

  setPoolMemberPricing: async (payload: {
    poolId: string;
    userId: string;
    pricing: "chargeable" | "offered" | "automatic";
  }): Promise<void> => {
    await httpsCallable("setPoolMemberPricing")(payload);
  },

  setSchemaDefaultVisibility: async (payload: {
    schemaId: string;
    defaultVisibility: SchemaDefaultVisibility;
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (
      await httpsCallable("setSchemaDefaultVisibility")(payload)
    ).data;

    return { updatedAt: new Date(updatedAt) };
  },

  searchMessagesInRoom: async (payload: {
    roomId: string;
    query: string;
  }): Promise<
    {
      id: string;
      roomId: string;
      poolId: string;
      createdAt: Date;
      matches: string[];
    }[]
  > => {
    return (
      await httpsCallable("searchMessagesInRoom")({
        roomId: payload.roomId,
        search: payload.query,
      })
    ).data;
  },

  setRoomEmoji: async (payload: {
    roomId: string;
    emoji: string;
  }): Promise<void> => {
    await httpsCallable("setRoomEmoji")(payload);
  },

  geocode: async (payload: {
    address: string;
  }): Promise<GeoLocation | undefined> => {
    return (await httpsCallable("geocode")(payload)).data;
  },

  reverseGeocode: async (payload: {
    language: string;
    coords: { latitude: number; longitude: number };
  }): Promise<GeoLocation | undefined> => {
    return (await httpsCallable("reverseGeocode")(payload)).data;
  },

  call<R>(endpoint: string, payload: unknown): Promise<R> {
    return httpsCallable(endpoint)(payload) as any;
  },

  duplicateSchema: async (payload: {
    sourceSchemaId: string;
    targetPoolId: string;
  }) => {
    await httpsCallable("duplicateSchema")(payload);
  },

  duplicateWorkflow: async (payload: { workflowId: string }) => {
    const result = await httpsCallable("duplicateWorkflow")(payload);
    return {
      createdAt: new Date(result.data.createdAt),
      workflowId: result.data.workflowId,
    };
  },

  reorderModularFolder: async (payload: {
    roomId: string;
    folderId: string;
    afterId?: string;
  }): Promise<{ updatedAt: Date }> => {
    const { updatedAt } = (await httpsCallable("reorderModularFolder")(payload))
      .data;

    return { updatedAt: new Date(updatedAt) };
  },

  getSchemaAsJson: async (payload: {
    schemaId: string;
  }): Promise<string> => {
    const { data } = await httpsCallable("getSchemaAsJson")(payload);
    return data;
  },

  importSchemaFromJson: async (payload: {
    value: string;
    targetPoolId: string;
  }) => {
    await httpsCallable("importSchemaFromJson")(payload);
  },

  setLibrarySchemaCompany: async (payload: {
    librarySchemaId: string;
    companyId: string | undefined;
  }) => {
    const { updatedAt } = (
      await httpsCallable("setLibrarySchemaCompany")(payload)
    ).data;

    return { updatedAt: new Date(updatedAt) };
  },

  setLibrarySchemaTags: async (payload: {
    librarySchemaId: string;
    tagIds: Array<string>;
  }) => {
    const { updatedAt } = (await httpsCallable("setLibrarySchemaTags")(payload))
      .data;

    return { updatedAt: new Date(updatedAt) };
  },

  setPoolCompany: async (payload: {
    poolId: string;
    companyId: string | undefined;
  }) => {
    await httpsCallable("setPoolCompany")(payload);
  },

  addCompany: async (payload: {
    name: string;
  }) => {
    await httpsCallable("addCompany")(payload);
  },

  deleteCompany: async (payload: {
    companyId: string;
  }) => {
    await httpsCallable("deleteCompany")(payload);
  },

  setCompanyColor: async (payload: {
    companyId: string;
    color: string;
  }) => {
    await httpsCallable("setCompanyColor")(payload);
  },

  markRoomAsUnread: async (payload: {
    roomId: string;
  }) => {
    await httpsCallable("markRoomAsUnread")(payload);
  },

  getRoomCards: async (payload: {
    poolId: string;
    filters: {
      responsibleId?: string;
      statusId?: string;
      searchText?: string;
      labelIds: string[];
      visibility: string;
      showArchived: boolean;
    };
    startAfter?: {
      id: string;
      lastEventAt: string;
    };
    endBefore?: {
      id: string;
      lastEventAt: string;
    };
    limit: number;
  }): Promise<{ data: FirestoreRoomCard[] }> =>
    await httpsCallable("getRoomCards")(payload),

  pinRoom: async (payload: {
    roomId: string;
    poolId: string;
  }) => {
    await httpsCallable("pinRoom")(payload);
  },

  unpinRoom: async (payload: {
    roomId: string;
    poolId: string;
  }) => {
    await httpsCallable("unpinRoom")(payload);
  },

  editSchemaElementDescription: async (payload: {
    schemaId: string;
    elementKey: string;
    text: string | undefined;
    image: string | null | undefined;
    document: string | null | undefined;
  }): Promise<{ updatedAt: Date }> => {
    const {
      data: { updatedAt },
    } = await httpsCallable("editSchemaElementDescription")(payload);
    return { updatedAt: new Date(updatedAt) };
  },

  editLibrarySchemaElementDescription: async (payload: {
    librarySchemaId: string;
    elementKey: string;
    text: string | undefined;
    image: string | null | undefined;
    document: string | null | undefined;
  }): Promise<{ updatedAt: Date }> => {
    const {
      data: { updatedAt },
    } = await httpsCallable("editLibrarySchemaElementDescription")(payload);
    return { updatedAt: new Date(updatedAt) };
  },
  addSchemaLibraryTag: async (payload: {
    language: LibrarySchemaLanguage;
    name: string;
  }): Promise<{ id: string }> => {
    const {
      data: { id },
    } = await httpsCallable("addSchemaLibraryTag")(payload);
    return { id };
  },
  deleteSchemaLibraryTag: async (payload: {
    id: string;
    language: LibrarySchemaLanguage;
  }): Promise<void> => {
    await httpsCallable("deleteSchemaLibraryTag")(payload);
  },
  reorderSchemaLibraryTags: async (payload: {
    orderedIds: Array<string>;
    language: LibrarySchemaLanguage;
  }): Promise<{ updatedAt: Date }> => {
    const {
      data: { updatedAt },
    } = await httpsCallable("reorderSchemaLibraryTags")(payload);
    return { updatedAt: new Date(updatedAt) };
  },
  renameSchemaLibraryTag: async (payload: {
    id: string;
    name: string;
    language: LibrarySchemaLanguage;
  }): Promise<{ updatedAt: Date }> => {
    const {
      data: { updatedAt },
    } = await httpsCallable("renameSchemaLibraryTag")(payload);
    return { updatedAt: new Date(updatedAt) };
  },
};

interface GetBillingInformationResult {
  trialPeriodEnd: string | undefined;
  billingRestriction: "restricted" | "unrestricted";
  restrictionScheme: "restricted" | "unrestricted" | "automatic";
  licenses: number | undefined;
  stripeSubscriptionId: string | undefined;
  stripeCustomerPortalUrl: string | undefined;
}

export interface RoomReportFiltersJson {
  dateRange?: {
    from?: string;
    until?: string;
  };
  includeMessages: boolean;
  includeDocuments: boolean;
  checkboxIds?: string[];
  folderIds?: string[];
}

export type ApiServiceType = typeof Api;

export { Api };

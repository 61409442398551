import { useCallback } from "react";
import { FlatList, StyleSheet, TouchableOpacity, View } from "react-native";
import type { ListRenderItem } from "@react-native/virtualized-lists";

import { Hoverable } from "@kraaft/shared/components/hoverable";
import { LibrarySchema } from "@kraaft/shared/core/modules/librarySchema/librarySchema.state";
import { conditionalEntry } from "@kraaft/shared/core/utils/utils";
import { Color, ColorStyle, Sheet, Spacing, Text } from "@kraaft/ui";

export type SuggestionsSheetProps = {
  items: Array<LibrarySchema>;
  onClick: (value: LibrarySchema) => void;
};

export const SchemaLibrarySuggestionsSheet = Sheet({
  web: "anchored",
}).create<SuggestionsSheetProps>(
  ({ Paper }) =>
    ({ onClose, items, onClick }) => {
      const renderSeparator = useCallback(() => {
        return <View style={styles.separator} />;
      }, []);

      const keyExtractor = useCallback((item: LibrarySchema) => {
        return item.id;
      }, []);

      const handleOnItemClicked = useCallback(
        (item: LibrarySchema) => {
          onClick?.(item);
          onClose();
        },
        [onClick, onClose],
      );

      const renderItem = useCallback<ListRenderItem<LibrarySchema>>(
        ({ item }) => {
          return (
            <Hoverable>
              {({ hoverProps, isHovered }) => (
                <TouchableOpacity
                  accessibilityLabel={item.name}
                  style={[
                    styles.suggestion,
                    conditionalEntry(styles.suggestionHovered, isHovered),
                  ]}
                  onPress={() => handleOnItemClicked(item)}
                  {...hoverProps}
                >
                  <Text>{item.name}</Text>
                </TouchableOpacity>
              )}
            </Hoverable>
          );
        },
        [handleOnItemClicked],
      );

      return (
        <Paper noPadding noGap>
          <FlatList
            data={items}
            style={styles.container}
            ItemSeparatorComponent={renderSeparator}
            renderItem={renderItem}
            keyExtractor={keyExtractor}
          />
        </Paper>
      );
    },
);

export const styles = StyleSheet.create({
  container: {
    maxHeight: 200,
  },
  suggestion: {
    height: Spacing.S32,
    justifyContent: "center",
    paddingHorizontal: Spacing.S12,
  },
  suggestionHovered: {
    backgroundColor: ColorStyle.ACTION_HOVERED,
  },
  separator: {
    height: StyleSheet.hairlineWidth,
    backgroundColor: Color.GREY_FRENCH,
    marginHorizontal: Spacing.S12,
  },
});

import { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { newConfirmationAlert } from "@kraaft/shared/components/confirmationAlert/confirmationAlert";
import { useDownloadReport } from "@kraaft/shared/components/modularFolders/modularFolderDetails/modularFolderDetailsFooter/useDownloadReport";
import { getModularRecordTitle } from "@kraaft/shared/components/modularFolders/modularFolderUtils";
import { useReportTemplateSelection } from "@kraaft/shared/components/reportTemplate/useReportTemplateSelection";
import { fileSaver } from "@kraaft/shared/core/modules/file/fileSaver";
import {
  OfflineModularFolderActions,
  OfflineModularFolderSelectors,
} from "@kraaft/shared/core/modules/modularFolder/modularFolder.offline";
import { ModularRecordUtils } from "@kraaft/shared/core/modules/schema/modularRecord.utils";
import { KSchema } from "@kraaft/shared/core/modules/schema/modularTypes/kSchema";
import { ModularRecord } from "@kraaft/shared/core/modules/schema/modularTypes/modularRecord";
import { Api } from "@kraaft/shared/core/services/api";
import { LegacyContextMenuButton } from "@kraaft/web/src/components/contextMenuButton";

import { ActionSheetItem } from "@kraaft/shared/components/legacyActionSheet/legacyActionSheetProps";

interface Props {
  row: ModularRecord;
  schema: KSchema;
  lockingColumns: string[];
}

const ModularActionsCell = (props: Props) => {
  const { row, schema } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const menuRef = useRef(null);

  const modularFolder = useSelector(
    OfflineModularFolderSelectors.select(row.id),
  );

  const { downloadReport } = useDownloadReport({
    schemaId: schema.id,
    modularFolderId: modularFolder?.id ?? "",
  });

  const { ReportTemplateSelection, openReportTemplateSelection } =
    useReportTemplateSelection({
      schemaId: schema.id,
      callback: downloadReport,
      anchorRef: menuRef,
      allowPDF: true,
    });

  const handleExtractPhotos = useCallback(async () => {
    const response = await Api.exportModularFolderFiles({
      folderId: row.id,
    });
    if (response) {
      await fileSaver.download(response.downloadUrl, response.filename);
    }
  }, [row.id]);

  const handleDeleteModularFolder = useCallback(() => {
    const title = getModularRecordTitle(modularFolder);

    newConfirmationAlert({
      title: t("confirmDeletePointTitle"),
      message: t("confirmDeletePointMessage", {
        name: title,
      }),
      confirmText: t("delete"),
      onConfirm: () =>
        dispatch(
          OfflineModularFolderActions.delete({
            id: row.id,
          }),
        ),
    });
  }, [dispatch, modularFolder, row.id, t]);

  const hasAtLeastOneLockedColumn = useMemo(
    () =>
      ModularRecordUtils.hasAtLeastOneLockedColumn(
        props.row.properties,
        props.lockingColumns,
      ),
    [props.lockingColumns, props.row.properties],
  );

  const actions: ActionSheetItem[] = hasAtLeastOneLockedColumn
    ? [
        {
          label: t("exportModularFolder"),
          style: "default",
          onPress: openReportTemplateSelection,
        },
        {
          label: t("extractModularFolder"),
          style: "default",
          onPress: handleExtractPhotos,
        },
      ]
    : [
        {
          label: t("exportModularFolder"),
          style: "default",
          onPress: openReportTemplateSelection,
        },
        {
          label: t("extractModularFolder"),
          style: "default",
          onPress: handleExtractPhotos,
        },
        {
          label: t("deleteModularFolder"),
          style: "destructive",
          onPress: handleDeleteModularFolder,
        },
      ];

  return (
    <>
      <LegacyContextMenuButton anchorRef={menuRef} actions={actions} />
      {ReportTemplateSelection}
    </>
  );
};

export { ModularActionsCell };
